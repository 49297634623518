import React from "react";
import DataTable, { TColumn } from "@commercetools-uikit/data-table";
import {
  usePaginationState,
  useDataTableSortingState,
} from "@commercetools-uikit/hooks";
import LoadingSpinner from "@commercetools-uikit/loading-spinner";
import Text from "@commercetools-uikit/text";
import { Pagination } from "@commercetools-uikit/pagination";
import SecondaryButton from "@commercetools-uikit/secondary-button";
import { CategoryPagedQueryResponse } from "@commercetools/platform-sdk";
import { useHistory, useRouteMatch } from "react-router-dom";
import Spacings from "@commercetools-uikit/spacings";
import { DEFAULT_LOCALE } from "../../../constants";
import { FilterOptions } from "./product-ranges-filters";
import { useRangeCategories } from "../../../hooks/connectors/use-category-connector";

const columns: TColumn[] = [
  { key: "name", label: "Range Name", isSortable: true },
  { key: "supplier", label: "Range Supplier" },
  { key: "actions", label: "", width: "200px" },
];

type ProductRangesProps = {
  filters: FilterOptions;
};

export const ProductRangesTable: React.FC<ProductRangesProps> = (props) => {
  const { push } = useHistory();
  const match = useRouteMatch();
  const { page, perPage } = usePaginationState();

  const tableSorting = useDataTableSortingState({
    key: `name.${DEFAULT_LOCALE}`,
    order: "asc",
  });

  const { data, loading } = useRangeCategories({
    page,
    perPage,
    sort: tableSorting.value,
    filters: props.filters,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {data ? (
        <>
          <DataTable<NonNullable<CategoryPagedQueryResponse["results"]>[0]>
            columns={columns}
            rows={data.results}
            itemRenderer={(item, column) => {
              switch (column.key) {
                case "name":
                  return (
                    <Spacings.Stack>
                      <Text.Body>{item.name[DEFAULT_LOCALE]}</Text.Body>
                      <Text.Detail>Key: {item.key}</Text.Detail>
                    </Spacings.Stack>
                  );
                case "supplier":
                  return (
                    <Spacings.Stack>
                      <Text.Body>
                        {item.custom?.fields.rangeSupplierId.obj.key}
                      </Text.Body>
                      <Text.Detail>
                        Key: {item.custom?.fields.rangeSupplierId.id}
                      </Text.Detail>
                    </Spacings.Stack>
                  );
                case "actions":
                  return (
                    <SecondaryButton
                      label="Manage Range"
                      onClick={() => push(`${match.url}/${item.id}`)}
                      size="20"
                    />
                  );
                default:
                  return null;
              }
            }}
            sortedBy={
              tableSorting.value.key.startsWith("name.")
                ? "name"
                : tableSorting.value.key
            }
            sortDirection={tableSorting.value.order}
            onSortChange={(columnKey, direction) => {
              if (columnKey === "name") {
                tableSorting.onChange(`name.${DEFAULT_LOCALE}`, direction);
              } else {
                tableSorting.onChange(columnKey, direction);
              }
            }}
          />
          <Pagination
            page={page.value}
            onPageChange={page.onChange}
            perPage={perPage.value}
            onPerPageChange={perPage.onChange}
            totalItems={data.total as number}
          />
        </>
      ) : null}
    </>
  );
};
