import TextInput from '@commercetools-uikit/text-input'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { Product, ProductVariant } from '@commercetools/platform-sdk'
import { useInventoryServices, useProductServices } from '../../../hooks'
import {
  MAX_INVENTORY_AMOUNT,
  PRODUCT_KEY_VALIDATION_EXPRESSION,
} from '../../../constants'
import { mapDuplicateProductDraft } from '../../../services'
import { ProductNotFoundError } from '../ProductNotFoundError'

type ValidationError = {
  productKey?: { missing?: boolean; duplicate?: boolean; invalid?: boolean }
  name?: { missing?: boolean; invalid?: boolean }
}

type ProductDuplicateFormValues = {
  productKey: string
  name: string
}

export type ProductDuplicateForm = ReturnType<
  typeof useFormik<ProductDuplicateFormValues>
>

type ProductDuplicateFormProps = {
  productDuplicating: Product
  values: ProductDuplicateFormValues
  onSubmitted: (productSku: string) => void
  onFailed: () => void
}

export const useProductDuplicateForm = (props: ProductDuplicateFormProps) => {
  const { fetchProductByKey, createNewProduct } = useProductServices()
  const { updateVariantInventoryBySku } = useInventoryServices()

  const validate = async (formikValues: ProductDuplicateFormValues) => {
    const errors: ValidationError = {}

    if (TextInput.isEmpty(formikValues.name)) {
      errors.name = { missing: true }
    }

    if (TextInput.isEmpty(formikValues.productKey)) {
      errors.productKey = { missing: true }
    }

    if (
      formikValues.productKey?.length > 0 &&
      !PRODUCT_KEY_VALIDATION_EXPRESSION.test(formikValues.productKey)
    ) {
      errors.productKey = { invalid: true }
    }

    if (Object.keys(errors).length > 0) {
      return errors
    }

    try {
      const existingProduct = await fetchProductByKey(formikValues.productKey)
      if (existingProduct) {
        errors.productKey = { duplicate: true }
      }
    } catch (error) {
      if (!(error instanceof ProductNotFoundError)) {
        throw error
      }
    }

    return errors
  }

  const handleSubmit = useCallback(
    async (formikValues: ProductDuplicateFormValues) => {
      try {
        const productDraft = mapDuplicateProductDraft(
          props.productDuplicating,
          formikValues.productKey,
          formikValues.name,
        )

        const duplicatedProduct = await createNewProduct(productDraft)

        const inventoryUpdates: (Promise<unknown> | Promise<unknown>[])[] = [
          updateVariantInventoryBySku(
            duplicatedProduct.masterData.staged.masterVariant.sku as string,
            MAX_INVENTORY_AMOUNT,
          ),
          duplicatedProduct.masterData.staged.variants.map(
            ({ sku }: ProductVariant) =>
              updateVariantInventoryBySku(sku as string, MAX_INVENTORY_AMOUNT),
          ),
        ]

        await Promise.all(inventoryUpdates)

        props.onSubmitted(duplicatedProduct.key as string)
      } catch (caught: unknown) {
        props.onFailed()
      }
    },
    [],
  )

  const formik = useFormik<ProductDuplicateFormValues>({
    initialValues: props.values,
    validate,
    onSubmit: values => handleSubmit(values),
    enableReinitialize: false,
  })

  return { formik }
}
