import SelectInput, { TCustomEvent } from '@commercetools-uikit/select-input'
import React from 'react'

export const ProductActionDropdown: React.FC<{
  handleGoToProduct: () => void
  handleDuplicateProduct: () => void
  handleProductPdf: () => void
  handlePrintPdf: () => void
  allowDuplicateProduct: boolean
  allowProductPdf: boolean
  hasRenderedProof: boolean
}> = ({
  handleGoToProduct,
  allowDuplicateProduct,
  handleDuplicateProduct,
  allowProductPdf,
  handleProductPdf,
  handlePrintPdf,
  hasRenderedProof,
}) => {
  const options = React.useMemo(() => {
    const values = [
      {
        value: 'goToProduct',
        label: 'Go to product',
      },
      {
        value: 'duplicateProduct',
        label: 'Duplicate product',
        isDisabled: !allowDuplicateProduct,
      },
      {
        value: 'downloadPdf',
        label: 'Download PDF',
        isDisabled: !allowProductPdf,
      },
      {
        value: 'printPdf',
        label: 'Print PDF',
        isDisabled: !hasRenderedProof,
      },
    ]

    return values
  }, [
    allowDuplicateProduct,
    allowProductPdf,
    allowProductPdf,
    hasRenderedProof,
  ])

  const actionMap: { [key: string]: () => void } = {
    goToProduct: handleGoToProduct,
    duplicateProduct: handleDuplicateProduct,
    downloadPdf: handleProductPdf,
    printPdf: handlePrintPdf,
  }

  const handleDropdownChange = (event: TCustomEvent) => {
    if (Array.isArray(event.target.value)) {
      return
    }

    if (event?.target?.value) {
      actionMap[event.target.value]()
    }
  }

  return (
    <SelectInput
      placeholder="Actions"
      isSearchable={false}
      options={options}
      onChange={handleDropdownChange}
      data-testid="product-actions-dropdown"
    />
  )
}
