import Spacings from '@commercetools-uikit/spacings'
import { FC } from 'react'

type MainLayoutProps = {
  heading?: React.ReactNode
}

export const MainLayout: FC<MainLayoutProps> = ({ heading, children }) => {
  return (
    <Spacings.Inset scale="xl">
      <Spacings.Stack scale="m">
        {heading && heading}

        {children}
      </Spacings.Stack>
    </Spacings.Inset>
  )
}
