import React from 'react'
import Spacings from '@commercetools-uikit/spacings'
import Text from '@commercetools-uikit/text'
import { MainLayout } from '../layouts/main-layout'
import { ProductRangesTable } from './components/product-ranges-table'
import {
  FilterOptions,
  ProductRangesFilter,
} from './components/product-ranges-filters'

const Heading = () => {
  return (
    <Spacings.Stack alignItems="stretch" scale="l">
      <Spacings.Inline
        alignItems="flex-start"
        justifyContent="space-between"
        scale="s"
      >
        <Spacings.Inline alignItems="center" scale="s">
          <Text.Headline as="h1">Product Ranges</Text.Headline>
        </Spacings.Inline>
      </Spacings.Inline>
    </Spacings.Stack>
  )
}

export const ProductRangesContainer = () => {
  const [filters, setFilters] = React.useState<FilterOptions>({
    name: '',
    supplierIds: [],
  })

  const handleFilterChange = <A extends keyof FilterOptions>(
    attribute: A,
    value: FilterOptions[A],
  ) => {
    setFilters(prev => {
      return {
        ...prev,
        [`${attribute}`]: value,
      }
    })
  }

  return (
    <MainLayout heading={<Heading />}>
      <>
        <ProductRangesFilter
          name={filters.name}
          supplierIds={filters.supplierIds}
          onFilterChange={handleFilterChange}
        />
        <ProductRangesTable filters={filters} />
      </>
    </MainLayout>
  )
}
