import {
  InvalidLayoutError,
  InvalidMasterShapeError,
  InvalidNumberOfTemplatesError,
  InvalidTemplateIdError,
  ValidationError,
} from '@moonpig/studio-product-personalisation-types'
import { InvalidMappingResult } from '../types'

const isValidationError = (error: unknown): error is ValidationError =>
  !!(error as ValidationError).code

const mapper = (error: ValidationError) => {
  let index
  let message

  switch (error.code) {
    case 'InvalidTemplateId': {
      const e: InvalidTemplateIdError = error
      message = `The specified template id ${e.templateId} is not valid`
      index = e.index
      break
    }
    case 'InvalidMasterShape': {
      const e: InvalidMasterShapeError = error
      message = `The master variant shape ${e.masterVariantShape} is not valid`
      break
    }
    case 'InconsistentLayouts': {
      message = 'The template layouts are inconsistent'
      break
    }
    case 'InvalidLayout': {
      const e: InvalidLayoutError = error
      message = `The layout is not correct for this product`
      index = e.index
      break
    }
    case 'InvalidNumberOfTemplates': {
      const e: InvalidNumberOfTemplatesError = error
      if (e.exactly && e.exactly.length > 0) {
        message = `There must be exactly ${e.exactly.join(
          ' or ',
        )} pages for this product`
      } else if (e.maximumExpected && e.maximumExpected > 0) {
        message = `There must be at between ${e.minimumExpected} and ${e.maximumExpected} pages for this product`
      } else {
        message = `There must be at least ${e.minimumExpected} pages for this product`
      }
      break
    }
    case 'UsesDirectSmile': {
      message = `Template ${error.templateName} uses direct smile which is not supported`
      break
    }
    case 'InconsistentDimensions': {
      message =
        'The dimensions for the templates are inconsistent - please ensure they are all correct for this product type'
      break
    }
    default: {
      message = 'unknown error'
    }
  }

  return { message, index }
}

export const mapInvalidMappingResult = (result: InvalidMappingResult) => {
  return result.errors.filter(e => isValidationError(e)).map(e => mapper(e))
}
