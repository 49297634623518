import React from 'react'
import {
  ApplicationShell,
  setupGlobalErrorListener,
} from '@commercetools-frontend/application-shell'

import { Sdk } from '@commercetools-frontend/sdk'
import { handleActionError } from '@commercetools-frontend/actions-global'
// eslint-disable-next-line import/no-unresolved
import { ActionError } from '@commercetools-frontend/actions-global/dist/declarations/src/actions/handle-action-error'
import { TApplicationContext } from '@commercetools-frontend/application-shell-connectors'
import { ApplicationRoutes } from '../../routes'
import { loadMessages } from '../../load-messages'

// Ensure to set up the global error listener before any React component renders
// in order to catch possible errors on rendering/mounting.
setupGlobalErrorListener()

export function EntryPoint() {
  return (
    <ApplicationShell
      environment={
        window.app as TApplicationContext<NonNullable<unknown>>['environment']
      }
      onRegisterErrorListeners={({ dispatch }) => {
        Sdk.Get.errorHandler = error =>
          handleActionError(error as ActionError)(dispatch)
      }}
      applicationMessages={loadMessages}
    >
      <ApplicationRoutes />
    </ApplicationShell>
  )
}
