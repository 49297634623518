import React from 'react'
import Spacings from '@commercetools-uikit/spacings'
import TextInput from '@commercetools-uikit/text-input'
import { useDebouncedCallback } from 'use-debounce'
import { SupplierDropdown } from '../../ui/dropdowns/SupplierDropdown'

export type FilterOptions = {
  name: string
  supplierIds: string[]
}

type ProductRangesFilterProps = {
  name: string
  supplierIds: string[]
  onFilterChange: <A extends keyof FilterOptions>(
    attribute: A,
    value: FilterOptions[A],
  ) => void
}

export const ProductRangesFilter: React.FC<
  ProductRangesFilterProps
> = props => {
  const [name, setName] = React.useState<string>(props.name)

  const onFilterChange = React.useCallback(
    (
      attribute: keyof FilterOptions,
      value: FilterOptions[keyof FilterOptions],
    ) => {
      props.onFilterChange(attribute, value)
    },
    [props.onFilterChange],
  )

  const debouncedOnFilterChange = useDebouncedCallback(onFilterChange, 1000)

  return (
    <Spacings.Stack alignItems="stretch" scale="l">
      <Spacings.Inline alignItems="flex-start" scale="s">
        <TextInput
          horizontalConstraint={7}
          placeholder="Search By Name or Key"
          name="name"
          value={name}
          onChange={event => {
            setName(event.target.value)
            debouncedOnFilterChange('name', event.target.value)
          }}
        />

        <SupplierDropdown
          horizontalConstraint={7}
          isMulti
          hideLabel
          onSuppliersSelected={ids => props.onFilterChange('supplierIds', ids)}
        />
      </Spacings.Inline>
    </Spacings.Stack>
  )
}
