import { v4 as uuid } from "uuid";
import Text from "@commercetools-uikit/text";
import Spacings from "@commercetools-uikit/spacings";
import TextField from "@commercetools-uikit/text-field";
import SelectField from "@commercetools-uikit/select-field";
import { ProductType } from "@commercetools/platform-sdk";
import React, { useMemo, useRef } from "react";
import SecondaryButton from "@commercetools-uikit/secondary-button";
import { CopyIcon } from "@commercetools-uikit/icons";
import { ProductImageSceneForm } from "../hooks/use-product-image-scene-form";
import { renderZipFile } from "../../../services/renderZipFile";
import { ProductImageScenesFormScenesTable } from "./product-image-scenes-form-scenes";

type ProductImageScenesFormProps = {
  productTypes: ProductType[];
  formik: ProductImageSceneForm;
};

export const ProductImageScenesForm = (props: ProductImageScenesFormProps) => {
  const inputElementRef = useRef<HTMLInputElement | null>(null);

  const { formik } = props;

  const productTypeOptions = useMemo(() => {
    return props.productTypes
      .map((type) => ({
        value: type.key as string,
        label: type.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [props.productTypes]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const scenes = await Promise.all(
        Array.from(e.target.files).map(async (zipFile: File) => {
          const id = uuid();
          const preview = await renderZipFile(zipFile);

          return {
            zipFile,
            id,
            preview,
          };
        })
      ).then((promises) =>
        promises.map((file) => ({
          id: file.id,
          status: "new",
          zip: file.zipFile,
          preview: file.preview,
          name: file.zipFile.name,
        }))
      );

      await formik.setFieldValue("scenes", [
        ...formik.values.scenes,
        ...scenes,
      ]);
    }
  };

  return (
    <Spacings.Stack scale="l">
      <Spacings.Inline>
        <TextField
          name="name"
          title={"Name"}
          value={formik.values.name}
          errors={
            TextField.toFieldErrors<ProductImageSceneForm["values"]>(
              formik.errors
            ).name
          }
          touched={formik.touched.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          renderError={(errorKey) => {
            if (errorKey === "duplicate") {
              return "A collection already exists with this name and product type.";
            }
            return null;
          }}
          isRequired
          horizontalConstraint={13}
        />
        <SelectField
          isDisabled={formik.values.id !== undefined}
          name="productType"
          title={"Product Type"}
          horizontalConstraint={13}
          isSearchable={true}
          isMulti={false}
          isClearable={true}
          value={formik.values.productType}
          touched={formik.touched.productType}
          placeholder="Search by product type"
          errors={
            SelectField.toFieldErrors<ProductImageSceneForm["values"]>(
              formik.errors
            ).productType
          }
          options={productTypeOptions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isRequired
        />
      </Spacings.Inline>

      <Spacings.Inline alignItems="center">
        <SecondaryButton
          iconLeft={<CopyIcon />}
          label={"Upload new ZIP file(s)"}
          onClick={() =>
            inputElementRef.current && inputElementRef.current.click()
          }
          size="10"
        />
        {formik.errors.scenes && (
          <Text.Detail tone="critical">
            You must include some zip files to upload
          </Text.Detail>
        )}
      </Spacings.Inline>

      <input
        ref={inputElementRef}
        multiple={true}
        data-testid="zip-upload"
        id="zip-upload"
        type={"file"}
        onChange={handleInputChange}
        accept={".zip"}
        style={{ display: "none" }}
        hidden
      />

      <ProductImageScenesFormScenesTable
        scenes={formik.values.scenes}
        onScenesChange={(scenes) => formik.setFieldValue("scenes", scenes)}
      />
    </Spacings.Stack>
  );
};
