import React, { useEffect, useMemo, useState } from 'react'
import Label from '@commercetools-uikit/label'
import SelectInput, {
  TCustomEvent,
  TSelectInputProps,
} from '@commercetools-uikit/select-input'
import FlatButton from '@commercetools-uikit/flat-button'
import style from './range-dropdown.mod.css'
import { useRanges } from './useRanges'

type RangeDropdownProps = {
  supplierIds: string[]
  onRangesSelected: (rangeIds: string[]) => void
  isMulti: boolean
  horizontalConstraint: TSelectInputProps['horizontalConstraint']
  maximumRangeSelection: number
}

export function RangeDropdown({
  supplierIds,
  onRangesSelected,
  isMulti,
  horizontalConstraint,
  maximumRangeSelection = 250,
}: RangeDropdownProps) {
  const [selectedRangeIds, setSelectedRangeIds] = useState<string[]>([])
  const ranges = useRanges()
  const selectRanges = (rangeIds: string[]) => {
    setSelectedRangeIds(rangeIds)
    onRangesSelected(rangeIds)
  }

  const rangeOptions = useMemo(() => {
    const filteredRanges = ranges
      ?.filter(
        range =>
          range.name &&
          (supplierIds.length === 0 || supplierIds.includes(range.supplierId)),
      )
      ?.map(range => ({
        label: range.name,
        value: range.id,
      }))
    return {
      label: 'All',
      options: filteredRanges,
    }
  }, [supplierIds, ranges])

  useEffect(() => {
    selectRanges([])
  }, [supplierIds])

  function rangeGroupHeading(
    props: React.ComponentProps<typeof SelectInput.GroupHeading>,
  ) {
    const handleAllClick = () => {
      const supplierRanges = rangeOptions.options
        .map(r => r.value)
        .slice(0, maximumRangeSelection)

      selectRanges(supplierRanges)
    }
    return (
      <>
        {supplierIds.length > 0 && (
          <SelectInput.GroupHeading
            {...props}
            className={style['std-c-range-header']}
          >
            <FlatButton
              onClick={handleAllClick}
              label={
                rangeOptions.options.length < maximumRangeSelection
                  ? 'All'
                  : `First ${maximumRangeSelection}`
              }
              className={style['std-c-range-header-all']}
              disabled={supplierIds.length === 0}
              aria-label="Select all ranges"
            >
              All
            </FlatButton>
          </SelectInput.GroupHeading>
        )}
      </>
    )
  }

  const handleRangeChange = (event: TCustomEvent) => {
    let value: string[] = []
    if (event?.target?.value) {
      value = Array.isArray(event.target.value)
        ? event.target.value
        : [event.target.value]
    }
    setSelectedRangeIds(value)
    onRangesSelected(value)
  }

  const filterOption = (
    candidate: { value: string; label: string },
    input: string,
  ) => {
    if (candidate.value === 'all') return true

    const range = ranges.find(r => r.id === candidate.value)
    if (!range) return false

    const matchesInput =
      input.length === 0 ||
      candidate.label.toLowerCase().includes(input.toLowerCase())
    return matchesInput
  }

  return (
    <>
      <Label>{isMulti ? 'Ranges' : 'Range'}</Label>
      <SelectInput
        name="Select ranges"
        placeholder={isMulti ? 'Select ranges' : 'Select a range'}
        isSearchable
        isMulti={isMulti}
        options={[rangeOptions]}
        onChange={handleRangeChange}
        backspaceRemovesValue
        horizontalConstraint={horizontalConstraint}
        data-testid="range-dropdown"
        value={isMulti ? selectedRangeIds : selectedRangeIds[0]}
        showOptionGroupDivider={true}
        aria-label="Select ranges"
        filterOption={filterOption}
        isClearable
        closeMenuOnSelect
        components={{ GroupHeading: rangeGroupHeading }}
      />
    </>
  )
}
