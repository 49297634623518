import React, { FC, useState } from "react";
import { ProductType } from "@commercetools/platform-sdk";
import { ProductImageSceneCollection } from "@moonpig/studio-product-personalisation-types";
import Text from "@commercetools-uikit/text";
import SecondaryButton from "@commercetools-uikit/secondary-button";
import { PlusBoldIcon } from "@commercetools-uikit/icons";
import Spacings from "@commercetools-uikit/spacings";
import { Link as RouterLink, useHistory } from "react-router-dom";
import DataTable from "@commercetools-uikit/data-table";
import LoadingSpinner from "@commercetools-uikit/loading-spinner";
import TextInput from "@commercetools-uikit/text-input";
import SelectInput, { TCustomEvent } from "@commercetools-uikit/select-input";
import { useProductImageSceneCollectionsFetcher } from "../../hooks/connectors/use-product-image-scenes-connector";
import { useApplicationUrl } from "../../hooks/useApplicationUrl";
import { MainLayout } from "../layouts/main-layout";
import { useProductTypes } from "../../hooks/useProductServices";

type Filters = {
  name?: string;
  productType?: string;
  forward?: boolean;
  lastEvaluatedKey?: Record<"pk" | "sk", string>;
};

const Heading = () => {
  const { url } = useApplicationUrl();

  return (
    <Spacings.Stack alignItems="stretch" scale="l">
      <Spacings.Inline
        alignItems="flex-start"
        justifyContent="space-between"
        scale="s"
      >
        <Spacings.Inline alignItems="center" scale="s">
          <Text.Headline as="h1">Product Image Scenes</Text.Headline>
        </Spacings.Inline>
        <Spacings.Inline alignItems="center" scale="s">
          <SecondaryButton
            as={RouterLink}
            to={url("product-image-scenes/new")}
            iconLeft={<PlusBoldIcon />}
            label="New Product Image Scene"
            size="20"
          />
        </Spacings.Inline>
      </Spacings.Inline>
    </Spacings.Stack>
  );
};

type ProductImageScenesFilterProps = {
  productTypes: ProductType[];
  filters: Filters;
  onFilterChange: <K extends keyof Pick<Filters, "name" | "productType">>(
    attribute: K,
    value: Filters[K] | undefined
  ) => void;
};

const ProductImageScenesFilter: FC<ProductImageScenesFilterProps> = ({
  productTypes,
  filters,
  onFilterChange,
}) => {
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    onFilterChange("name", value.length !== 0 ? value : undefined);
  };

  const onProductTypeChange = (event: TCustomEvent) => {
    const { value } = event.target;
    const productType = Array.isArray(value) ? value[0] : value;

    onFilterChange(
      "productType",
      productType !== null ? productType : undefined
    );
  };

  return (
    <Spacings.Stack alignItems="stretch" scale="l">
      <Spacings.Inline alignItems="flex-start" scale="s">
        <TextInput
          id="name"
          name="name"
          value={filters.name ?? ""}
          placeholder="Search by name"
          onChange={onNameChange}
          horizontalConstraint={7}
        />
        <SelectInput
          aria-label="Product Type"
          id="productType"
          name="productType"
          horizontalConstraint={7}
          isSearchable={true}
          isMulti={false}
          isClearable={true}
          value={filters.productType}
          placeholder="Search by product type"
          options={productTypes.map((type) => ({
            value: type.key as string,
            label: type.name,
          }))}
          onChange={onProductTypeChange}
        />
      </Spacings.Inline>
    </Spacings.Stack>
  );
};

const columns = [
  { key: "name", label: "Name" },
  { key: "productType", label: "Product Type" },
  { key: "created", label: "Date Created" },
  { key: "updated", label: "Date Modified" },
];

type ProductImageScenesTableProps = {
  productTypes: ProductType[];
  scenes: ProductImageSceneCollection[];
};

const ProductImageScenesTable: FC<ProductImageScenesTableProps> = ({
  productTypes,
  scenes,
}) => {
  const history = useHistory();
  const { url } = useApplicationUrl();

  return (
    <>
      <DataTable
        columns={columns}
        rows={scenes}
        onRowClick={(row) =>
          history.push(url(`product-image-scenes/${row.id}`))
        }
        itemRenderer={(item, column) => {
          const productType = productTypes.find(
            (type) => (item.productType as string) === type.key
          );

          switch (column.key) {
            case "name":
              return item[column.key];
            case "productType":
              return productType ? productType.name : "Unknown";
            case "created":
            case "updated":
              return new Date(item[column.key].at).toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              });
            default:
              return null;
          }
        }}
      />
    </>
  );
};

type ProductImageScenesContainerProps = {
  productTypes: ProductType[];
};

const ProductImageScenes: FC<ProductImageScenesContainerProps> = ({
  productTypes,
}) => {
  const [filters, setFilters] = useState<Filters>({
    name: undefined,
    productType: undefined,
  });

  const { data, loading } = useProductImageSceneCollectionsFetcher({
    name: filters.name,
    productType: filters.productType,
  });

  const onFilterChange = <
    K extends keyof Pick<Filters, "name" | "productType">
  >(
    attribute: K,
    value: Filters[K] | undefined
  ) => {
    setFilters((prev) => ({
      ...prev,
      [attribute]: value,
    }));
  };

  return (
    <>
      <ProductImageScenesFilter
        productTypes={productTypes}
        filters={filters}
        onFilterChange={(attribute, value) => onFilterChange(attribute, value)}
      />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <ProductImageScenesTable
          productTypes={productTypes}
          scenes={data?.collections || []}
        />
      )}
    </>
  );
};

export const ProductImageScenesContainer = () => {
  const { loading, data, error } = useProductTypes();

  if (loading) return null;
  if (error) return null;
  if (!data) return null;

  return (
    <MainLayout heading={<Heading />}>
      <ProductImageScenes productTypes={data} />
    </MainLayout>
  );
};
