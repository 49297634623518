import { useApplicationContext } from '@commercetools-frontend/application-shell-connectors'
import { useMemo } from 'react'

export const useApplicationUser = () => {
  const { user: contextUser } = useApplicationContext(context => ({
    user: context.user,
  }))

  const user = useMemo(() => {
    if (!contextUser) return undefined

    return {
      id: contextUser?.id,
      firstName: contextUser?.firstName,
      lastName: contextUser?.lastName,
      email: contextUser?.idTokenUserInfo?.email ?? contextUser?.email,
    }
  }, [contextUser])

  return { user }
}
