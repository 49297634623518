import {
  actions,
  TSdkActionPost,
  useAsyncDispatch,
} from '@commercetools-frontend/sdk'
import { useApplicationContext } from '@commercetools-frontend/application-shell-connectors'
import { MAPPING_API_HOST } from '../../constants'

export type ProductPrintPdfBaseInput = {
  productId: string
  productType: string
  productSku: string
  productSize?: string
  productShape?: string
  quantity: number
  user: string
  factory: string
  method: 'send_to_them' | 'send_to_me'
  despatchOn: string
}

export type ProductPrintPdfInput = ProductPrintPdfBaseInput &
  (
    | {
        address: {
          type: 'office_london' | 'office_amsterdam' | 'office_manchester'
          firstName: string
          lastName: string
        }
      }
    | {
        address: {
          type: 'custom'
          firstName: string
          lastName: string
          houseNumber: string
          streetName: string
          postcode: string
          cityTown: string
          county: string
          country: 'GB' | 'NL'
        }
      }
  )

export const useProductPrintPdfPost = () => {
  const { projectKey } = useApplicationContext(context => ({
    projectKey: context.project?.key ?? '',
  }))

  const dispatch = useAsyncDispatch<TSdkActionPost, undefined>()

  const action = async (input: ProductPrintPdfInput) => {
    try {
      await dispatch(
        actions.post({
          uri: '/proxy/forward-to',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-Forward-To': `${MAPPING_API_HOST}/products/${input.productId}/print-proof`,
            'X-Project-Key': projectKey,
            'Accept-version': 'v2',
          },
          payload: input,
        }),
      )
    } catch (e) {
      throw new Error('ProductPrintPdfFailed')
    }
  }

  return { action }
}
