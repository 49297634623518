import { useApplicationContext } from '@commercetools-frontend/application-shell-connectors'
import { generatePath } from 'react-router-dom'

export const useApplicationUrl = () => {
  const { projectKey, entryPointUriPath } = useApplicationContext(context => ({
    projectKey: context.project?.key as string,
    entryPointUriPath: context.environment.entryPointUriPath,
  }))

  const extensionUrl = generatePath('/:projectKey/:entryPointUriPath', {
    projectKey,
    entryPointUriPath,
  })

  const url = (path: string) => {
    return `${extensionUrl}/${path}`
  }

  return { url, extensionUrl, projectKey }
}
