import {
  TApplicationContext,
  useApplicationContext,
} from '@commercetools-frontend/application-shell-connectors'
import TextInput from '@commercetools-uikit/text-input'
import NumberInput from '@commercetools-uikit/number-input'
import DateInput from '@commercetools-uikit/date-input'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import {
  ProductPrintPdfBaseInput,
  ProductPrintPdfInput,
  useProductPrintPdfPost,
} from '../../../../hooks/connectors/use-product-print-pdf-connector'
import { useApplicationUser } from '../../../../hooks/useApplicationUser'

export type ProductPrintFormValues = {
  productId: string
  productType: string
  productSku: string
  productSize?: string
  productShape?: string
  quantity: number
  method: 'send_to_me' | 'send_to_them'
  address_type: string
  addressFirstName: string
  addressLastName: string
  addressHouseNumber: string
  addressStreetName: string
  addressPostcode: string
  addressCityTown: string
  addressCounty: string
  addressCountry: 'GB' | 'NL'
  user: string
  factory: string
  despatchOn: string
}

type FormFieldError = Record<string, boolean>

type FormErrors<Values> = {
  [K in keyof Values]?: FormFieldError
}

export type ProductPrintForm = ReturnType<
  typeof useFormik<ProductPrintFormValues>
>

const validate = (
  values: ProductPrintFormValues,
): FormErrors<ProductPrintFormValues> => {
  const errors: FormErrors<ProductPrintFormValues> = {}

  if (NumberInput.isEmpty(values.quantity)) {
    errors.quantity = { missing: true }
  }

  if (values.quantity <= 0 || values.quantity > 10) {
    errors.quantity = { out_of_range: true }
  }

  if (
    values.factory === '' ||
    (Array.isArray(values.factory) && values.factory.length === 0)
  ) {
    errors.factory = { missing: true }
  }

  if (DateInput.isEmpty(values.despatchOn)) {
    errors.despatchOn = { missing: true }
  }

  if (
    values.productSku === '' ||
    (Array.isArray(values.productSku) && values.productSku.length === 0)
  ) {
    errors.productSku = { missing: true }
  }

  if (TextInput.isEmpty(values.addressFirstName)) {
    errors.addressFirstName = { missing: true }
  }

  if (TextInput.isEmpty(values.addressLastName)) {
    errors.addressLastName = { missing: true }
  }

  if (
    values.address_type === '' ||
    (Array.isArray(values.address_type) && values.address_type.length === 0)
  ) {
    errors.address_type = { missing: true }
  }

  if (values.address_type === 'custom') {
    if (TextInput.isEmpty(values.addressHouseNumber)) {
      errors.addressHouseNumber = { missing: true }
    }

    if (TextInput.isEmpty(values.addressStreetName)) {
      errors.addressStreetName = { missing: true }
    }

    if (TextInput.isEmpty(values.addressPostcode)) {
      errors.addressPostcode = { missing: true }
    }

    if (TextInput.isEmpty(values.addressCityTown)) {
      errors.addressCityTown = { missing: true }
    }

    if (TextInput.isEmpty(values.addressCounty)) {
      errors.addressCounty = { missing: true }
    }

    if (
      Array.isArray(values.addressCountry) &&
      values.addressCountry.length === 0
    ) {
      errors.addressCountry = { missing: true }
    }
  }

  return errors
}

const toPayloadInput = (
  values: ProductPrintFormValues,
): ProductPrintPdfInput => {
  const baseInput: ProductPrintPdfBaseInput = {
    productId: values.productId,
    productType: values.productType,
    productSku: values.productSku,
    productSize: values.productSize,
    productShape: values.productShape,
    quantity: values.quantity,
    user: values.user,
    method: values.method,
    factory: values.factory,
    despatchOn: values.despatchOn,
  }

  if (values.address_type.startsWith('office')) {
    return {
      ...baseInput,
      address: {
        type: values.address_type as
          | 'office_london'
          | 'office_amsterdam'
          | 'office_manchester',
        firstName: values.addressFirstName,
        lastName: values.addressLastName,
      },
    }
  }

  return {
    ...baseInput,
    address: {
      type: 'custom',
      firstName: values.addressFirstName,
      lastName: values.addressLastName,
      houseNumber: values.addressHouseNumber,
      streetName: values.addressStreetName,
      postcode: values.addressPostcode,
      cityTown: values.addressCityTown,
      county: values.addressCounty,
      country: values.addressCountry,
    },
  }
}

type UseProductPrintPdfForm = {
  productId: string
  productType: string
  productVariants: Array<{ sku: string; size?: string; shape?: string }>
  onSubmitted: () => void
  onFailed: () => void
}

export const useProductPrintPdfForm = (props: UseProductPrintPdfForm) => {
  const productPrintPdfPost = useProductPrintPdfPost()
  const { user } = useApplicationUser()

  const { defaultFormValues } = useApplicationContext(
    (
      context: TApplicationContext<{
        createTestPrintDefaultValues: { method?: 'send_to_them' | 'send_to_me' }
      }>,
    ) => ({
      projectKey: context.project?.key,
      defaultFormValues: context.environment.createTestPrintDefaultValues,
    }),
  )

  const handleSubmit = useCallback(
    async (formikValues: ProductPrintFormValues) => {
      try {
        const variant = props.productVariants.find(
          v => v.sku === formikValues.productSku,
        )

        const input: ProductPrintFormValues = {
          ...formikValues,
          productSize: variant?.size,
          productShape: variant?.shape,
        }

        const values = toPayloadInput(input)

        await productPrintPdfPost.action(values)

        props.onSubmitted()
      } catch (e) {
        props.onFailed()
      }
    },
    [],
  )

  const formik = useFormik<ProductPrintFormValues>({
    initialValues: {
      productId: props.productId,
      productType: props.productType,
      productSku: '',
      productSize: '',
      productShape: '',
      quantity: 1,
      address_type: '',
      addressFirstName: user?.firstName ?? '',
      addressLastName: user?.lastName ?? '',
      addressHouseNumber: '',
      addressStreetName: '',
      addressPostcode: '',
      addressCityTown: '',
      addressCounty: '',
      addressCountry: 'GB',
      user: user?.email ?? '',
      factory: '',
      method: defaultFormValues?.method ?? 'send_to_them',
      despatchOn: new Date().toISOString().split('T')[0],
    },
    validate,
    onSubmit: values => handleSubmit(values),
  })

  return {
    formik,
    handleSubmit,
  }
}

type FactoryOption = {
  key: string
  factory: string
  location: string
}

export const factoryOptions = (projectKey: string): FactoryOption[] => {
  const key = projectKey.split('-')[0]

  const options: Record<string, FactoryOption[]> = {
    moonpig: [
      { key: 'IRE01', factory: 'Ireland Dublin', location: 'Dublin, Ireland' },
      {
        key: 'GB-MNPG-TAM',
        factory: 'Moonpig Tamworth',
        location: 'Tamworth, UK',
      },
      { key: 'AU-PMI-ME', factory: 'PMI', location: 'Melbourne, Australia' },
      { key: 'GB-ARENA-DRO', factory: 'Arena', location: 'Droitwich, UK' },
      { key: 'GB-DPS-SLE', factory: 'DPS', location: 'Sleaford, UK' },
      {
        key: 'US-KODI-NYC',
        factory: 'Kodi Collective',
        location: 'New York, USA',
      },
      { key: 'GG-MNPG-GSY', factory: 'Guernsey', location: 'Guernsey' },
    ],
    greetz: [
      {
        key: 'NL-GRTZ-AMS',
        factory: 'Greetz Almere',
        location: 'Almere, Netherlands',
      },
    ],
  }

  return options[key]
}

type OfficeOption = {
  key: string
  label: string
}

export const officeOptions = (projectKey: string): OfficeOption[] => {
  const key = projectKey.split('-')[0]

  const options: Record<string, OfficeOption[]> = {
    moonpig: [
      { key: 'office_london', label: 'Herbal House (Moonpig)' },
      { key: 'office_manchester', label: 'Manchester (Moonpig)' },
    ],
    greetz: [{ key: 'office_amsterdam', label: 'Amsterdam (Greetz)' }],
  }

  return options[key]
}
