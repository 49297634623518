import React from "react";
import { CloseIcon } from "@commercetools-uikit/icons";
import IconButton from "@commercetools-uikit/icon-button";
import { ContentNotification } from "@commercetools-uikit/notifications";
import style from "./Notification.mod.css";

type NotificationProps = {
  type: "warning" | "error";
  children: React.ReactNode;
  onClose?: () => void;
  dismissable?: boolean;
};

export function Notification({
  type,
  children,
  onClose,
  dismissable = true,
}: NotificationProps) {
  return (
    <div className={style["std-c-notification"]}>
      <ContentNotification type={type}>
        <div className={style["std-c-notification__content-container"]}>
          {children}
        </div>
        {dismissable && onClose && (
          <div className={style["std-c-notification__btn-container"]}>
            <IconButton
              size="30"
              icon={<CloseIcon />}
              label="Close"
              onClick={onClose}
            />
          </div>
        )}
      </ContentNotification>
    </div>
  );
}
