import React, { useMemo } from "react";
import { BinFilledIcon, PlusBoldIcon } from "@commercetools-uikit/icons";
import PrimaryButton from "@commercetools-uikit/primary-button";
import IconButton from "@commercetools-uikit/icon-button";
import DataTable from "@commercetools-uikit/data-table";
import Text from "@commercetools-uikit/text";
import { ErrorMessage } from "@commercetools-uikit/messages";
import NumberInput from "@commercetools-uikit/number-input";
import { TemplateSearchField } from "./TemplateSearchField";
import style from "./TemplateMapping.mod.css";
import {
  MappingApiResponse,
  MappingEntry,
  MappingRow,
  SearchApiResponseResult,
} from "../../../types";
import { TemplatePreview } from "./TemplatePreview";

type TemplateMappingProperties = {
  productMapping: MappingApiResponse;
  setProductMapping: (mapping: MappingApiResponse) => void;
  emptyField: boolean;
  getErrorsForTemplate: (index: number) => string[];
  canSetCopies: boolean /* not happy about this so might add a new api */;
};

const getLastPageNumber = (mapping: MappingApiResponse | undefined): number => {
  const mappings = mapping?.mapping;
  if (mappings && mappings.length > 0) {
    return mappings[mappings.length - 1].index;
  }
  return 0;
};

export function TemplateMapping({
  productMapping,
  setProductMapping,
  emptyField,
  getErrorsForTemplate,
  canSetCopies,
}: TemplateMappingProperties) {
  const updatePage = (page: number, toApply: MappingEntry | undefined) => {
    const newMapping = productMapping.mapping;
    if (!toApply) {
      newMapping[page - 1] = {
        templateId: "",
        index: page,
        templateData: undefined,
        publishDate: undefined,
        copies: 1,
      };
    } else {
      newMapping[page - 1] = toApply;
    }
    setProductMapping({
      ...productMapping,
      mapping: newMapping,
    });
  };

  const removeLastPage = () => {
    setProductMapping({
      ...productMapping,
      mapping: productMapping.mapping.slice(0, -1),
    });
  };

  const addPage = () => {
    const page = getLastPageNumber(productMapping) + 1;
    const newMapping = productMapping.mapping;
    newMapping.push({
      templateId: "",
      index: page,
      templateData: undefined,
      publishDate: undefined,
      copies: 1,
    });

    setProductMapping({
      ...productMapping,
      mapping: newMapping,
    });
  };

  const indexColumn = {
    key: "index",
    label: "Page",
    width: "80px",
    align: "center" as const,
  };
  const previewColumn = {
    key: "preview",
    label: "Preview",
    width: "400px",
    align: "center" as const,
    renderItem: (row: SearchApiResponseResult) => {
      const { templateData } = row;
      return <TemplatePreview template={templateData} />;
    },
  };
  const nameColumn = {
    key: "name",
    label: "Name",
    renderItem: (row: MappingRow) => {
      const { templateData, index } = row;
      const errors = getErrorsForTemplate(index);
      const invalidName = emptyField && templateData?.name === "";
      return (
        <div>
          <TemplateSearchField
            id={`${index}-template-name`}
            key={`${index}-template-name`}
            value={row.templateId}
            label={templateData?.name ?? ""}
            placeholder={`Template Name for page ${index}`}
            hasError={invalidName}
            onSelected={({ templateData: selectedTemplateData }) => {
              const { preview, name, id: templateId } = selectedTemplateData;
              const { url: previewUrl } = preview;
              updatePage(index, {
                ...row,
                templateId,
                previewUrl,
                name,
                index,
                templateData: selectedTemplateData,
              } as MappingEntry);
            }}
            onCleared={() => {
              updatePage(index, undefined);
            }}
          />
          {errors.length > 0 && (
            <div
              data-testid={`mapping-errors-page-${index}`}
              className={style["std-c-product-row__warning-message"]}
            >
              {errors.map((error, i) => (
                <ErrorMessage key={i}>{error}</ErrorMessage>
              ))}
            </div>
          )}
        </div>
      );
    },
  };
  const deleteColumn = {
    key: "delete",
    label: "",
    width: "80px",
    renderItem: (row: MappingRow) => {
      const lastPageNumber = getLastPageNumber(productMapping);
      if (row.index === lastPageNumber) {
        return (
          <IconButton
            label="Delete Mapping"
            onClick={removeLastPage}
            icon={<BinFilledIcon />}
            size="40"
          />
        );
      }

      return <div />;
    },
  };

  const copiesColumn = {
    key: "copies",
    label: "Copies",
    width: "120px",
    renderItem: (row: MappingRow) => {
      const { index, copies } = row;
      return (
        <NumberInput
          data-testid={`${index}-copies-input`}
          key={`${index}-copies`}
          name={`${index}-copies`}
          value={copies ?? 1}
          placeholder={"1"}
          min={1}
          max={10}
          step={1}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            updatePage(index, {
              ...row,
              copies: Number(evt.target.value),
            } as MappingEntry);
          }}
        />
      );
    },
  };

  const columns = canSetCopies
    ? [indexColumn, previewColumn, nameColumn, copiesColumn, deleteColumn]
    : [indexColumn, previewColumn, nameColumn, deleteColumn];

  const mappingWithIds = useMemo<MappingRow[]>(() => {
    return productMapping
      ? productMapping.mapping.map((m) => ({
          ...m,
          copies: m.copies,
          id: `${m.index}`,
        }))
      : [];
  }, [productMapping]);

  const footer = (
    <PrimaryButton
      iconLeft={<PlusBoldIcon />}
      label="Add Template"
      onClick={addPage}
      isDisabled={false}
      size="10"
    />
  );

  return (
    <div
      data-testid="template-mapping"
      className={style["std-c-product-mapping-product-templates-section"]}
    >
      <Text.Headline as="h3">Templates</Text.Headline>
      <DataTable rows={mappingWithIds} columns={columns} footer={footer} />
    </div>
  );
}
