import React from 'react'
import { PublishedTemplate } from '@moonpig/moonpig-studio-templates/src/types/published'
import style from './TemplatePreview.mod.css'

type TemplatePreviewProps = {
  template: PublishedTemplate
}

export function TemplatePreview({ template }: TemplatePreviewProps) {
  return (
    <div className={style['std-c-product-template-row__image-container']}>
      {!template?.preview?.url && (
        <img
          className={style['std-c-product-template-row__image']}
          alt="Preview not available"
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOTggMjA0Ij48cmVjdCB3aWR0aD0iMjk4IiBoZWlnaHQ9IjIwNCIgc3R5bGU9ImZpbGw6I2RiZGJkYjsiLz48cGF0aCBkPSJtOTEsMTQ3Ljc2OTUxYzAsNi43NTUyMSw1LjQ5ODk4LDEyLjIzMDQ5LDEyLjIzMDQ5LDEyLjIzMDQ5aDkxLjUzOTAzYzYuNzU1MjEsMCwxMi4yMzA0OS01LjQ5ODk4LDEyLjIzMDQ5LTEyLjIzMDQ5VjU2LjIzMDQ5YzAtNi43NTUyMS01LjQ5ODk4LTEyLjIzMDQ5LTEyLjIzMDQ5LTEyLjIzMDQ5aC05MS41MzkwM2MtNi43NTUyMSwwLTEyLjIzMDQ5LDUuNDk4OTgtMTIuMjMwNDksMTIuMjMwNDl2OTEuNTM5MDNoMFptMTAzLjc2OTUxLDYuNDIzMzhoLTkxLjUzOTAyYy0zLjUzMTY3LDAtNi40MjMzOC0yLjg5MTcxLTYuNDIzMzgtNi40MjMzOHYtMTUuMjg4MTFsMjEuOTk1OTEtMjEuOTk1OTEsMTguNzk2MDgsMTguNzk2MDdjMS4xMzc3MiwxLjEzNzcyLDIuOTYyODEsMS4xMzc3Miw0LjEwMDUzLDBsMzMuOTQxOTctMzMuOTQxOTcsMjUuNTUxMjksMjUuNTUxMjl2MjYuODc4NjJjMCwzLjUzMTY3LTIuODkxNzEsNi40MjMzOC02LjQyMzM4LDYuNDIzMzhoMFpNMTAzLjIzMDQ5LDQ5LjgwNzExaDkxLjUzOTAzYzMuNTMxNjcsMCw2LjQyMzM4LDIuODkxNyw2LjQyMzM4LDYuNDIzMzd2NTYuNDM1NjRsLTIzLjUxMjg3LTIzLjQ4OTE3Yy0xLjEzNzcyLTEuMTM3NzItMi45NjI4MS0xLjEzNzcyLTQuMTAwNTMsMGwtMzMuOTQxOTcsMzMuOTQxOTYtMTguNzk2MDgtMTguNzk2MDhjLTEuMTM3NzItMS4xMzc3Mi0yLjk2MjgxLTEuMTM3NzItNC4xMDA1MywwbC0xOS45MzM4LDE5LjkzMzc5VjU2LjIzMDQ4YzAtMy41MzE2NywyLjg5MTctNi40MjMzOCw2LjQyMzM3LTYuNDIzMzhoMFoiLz48cGF0aCBkPSJtMTI2Ljk1NjY4LDkwLjQ4MDU5YzguMTUzNjYsMCwxNC43NjY2NS02LjYzNjcsMTQuNzY2NjUtMTQuNzY2NjVzLTYuNjM2Ny0xNC43NjY2NS0xNC43NjY2NS0xNC43NjY2NS0xNC43NjY2NSw2LjYzNjctMTQuNzY2NjUsMTQuNzY2NjUsNi42MTMsMTQuNzY2NjUsMTQuNzY2NjUsMTQuNzY2NjVabTAtMjMuNzI2MTljNC45NTM4MiwwLDguOTU5NTQsNC4wMjk0Miw4Ljk1OTU0LDguOTU5NTRzLTQuMDI5NDIsOC45NTk1NC04Ljk1OTU0LDguOTU5NTQtOC45NTk1NC00LjAyOTQyLTguOTU5NTQtOC45NTk1NCw0LjAwNTcyLTguOTU5NTQsOC45NTk1NC04Ljk1OTU0aDBaIi8+PC9zdmc+"
        />
      )}
      {template?.preview?.url && (
        <img
          src={template.preview.url}
          alt={template.name}
          className={style['std-c-product-template-row__image']}
        />
      )}
    </div>
  )
}
