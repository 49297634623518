import React, { useState } from 'react'
import Label from '@commercetools-uikit/label'
import SelectInput, {
  TCustomEvent,
  TSelectInputProps,
} from '@commercetools-uikit/select-input'
import { useSuppliers } from '../../../hooks/useSuppliers'

type SupplierDropdownProps = {
  onSuppliersSelected: (supplierIds: string[]) => void
  isMulti: boolean
  horizontalConstraint: TSelectInputProps['horizontalConstraint']
  hideLabel?: boolean
}

export function SupplierDropdown({
  onSuppliersSelected,
  isMulti,
  horizontalConstraint,
  hideLabel = false,
}: SupplierDropdownProps) {
  const [selectedSupplierIds, setSelectedSupplierIds] = useState<string[]>([])
  const suppliers = useSuppliers()
  const supplierOptions = suppliers
    .filter(supplier => supplier.name)
    .map(supplier => ({
      label: supplier.name,
      value: supplier.id,
    }))

  const handleSupplierChange = (event: TCustomEvent) => {
    let value: string[] = []
    if (event?.target?.value) {
      value = Array.isArray(event.target.value)
        ? event.target.value
        : [event.target.value]
    }
    setSelectedSupplierIds(value)
    onSuppliersSelected(value)
  }

  return (
    <>
      {!hideLabel && <Label>{isMulti ? 'Suppliers' : 'Supplier'}</Label>}
      <SelectInput
        name="Select a supplier"
        placeholder={isMulti ? 'Select suppliers' : 'Select a supplier'}
        isSearchable
        options={supplierOptions}
        onChange={handleSupplierChange}
        isMulti={isMulti}
        backspaceRemovesValue
        horizontalConstraint={horizontalConstraint}
        data-testid="supplier-dropdown"
        value={isMulti ? selectedSupplierIds : selectedSupplierIds[0]}
        showOptionGroupDivider={true}
        isClearable
        closeMenuOnSelect
        aria-label="Select suppliers"
      />
    </>
  )
}
