import { actions, useAsyncDispatch } from '@commercetools-frontend/sdk'
import { ProductImageSceneCollection } from '@moonpig/studio-product-personalisation-types'
import { useEffect, useState } from 'react'
import { MAPPING_API_HOST } from '../../../constants'
import { getProjectKey } from '../../../services'
import { isProductImageSceneCollectionsResult } from '../../../types'
import { HttpErrorType } from '../../../types/dispatch'

export const useProductImageSceneCollections = (productTypeKey: string) => {
  const [loading, setLoading] = useState<boolean>(true)

  const [productImageSceneCollections, setProductImageSceneCollections] =
    useState<ProductImageSceneCollection[]>([])
  const dispatch = useAsyncDispatch()

  useEffect(() => {
    const getProductImageSceneCollections = async () => {
      setLoading(true)

      const getRequest = actions.get({
        uri: '/proxy/forward-to',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'X-Forward-To': `${MAPPING_API_HOST}/productImageSceneCollections/${productTypeKey}`,
          'X-Project-Key': getProjectKey(),
          'Accept-version': 'v2',
        },
      })

      try {
        if (!productTypeKey) return
        const result = await dispatch(getRequest)
        if (isProductImageSceneCollectionsResult(result)) {
          setProductImageSceneCollections(result)
        }
      } catch (err: unknown) {
        setLoading(false)
        if ((err as HttpErrorType).code !== 404) {
          throw err
        }
        setProductImageSceneCollections([])
      }

      setLoading(false)
    }

    getProductImageSceneCollections()
  }, [productTypeKey, dispatch])

  return {
    productImageSceneCollections,
    loading,
  }
}
