import { Channel, ChannelPagedQueryResponse } from '@commercetools/platform-sdk'
import { actions, useAsyncDispatch } from '@commercetools-frontend/sdk'
import { useEffect, useRef, useState } from 'react'
import { DEFAULT_LOCALE } from '../constants'
import { Supplier } from '../types/range'

export const useSuppliers = (): Supplier[] => {
  const cancelled = useRef(false)

  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const dispatch = useAsyncDispatch()

  const getSuppliers = async (
    page = 1,
    suppliersToInclude: Supplier[] = [],
  ): Promise<Supplier[]> => {
    const getRequest = actions.get({
      service: 'channels',
      options: {
        where: ['roles contains all ("OrderImport")'],
        perPage: 500,
        page,
        sort: [{ by: `name.${DEFAULT_LOCALE}`, direction: 'asc' }],
      },
    })

    const response = (await dispatch(getRequest)) as ChannelPagedQueryResponse
    const { results, offset, total } = response
    const mapped = results.map((s: Channel) => ({
      id: s.id,
      name: s.name?.[DEFAULT_LOCALE] ?? 'unknown',
    }))

    const updatedSuppliers = [...suppliersToInclude, ...mapped]

    if (offset + results.length >= (total ?? 0)) {
      if (!cancelled.current) {
        setSuppliers(updatedSuppliers)
      }

      return updatedSuppliers
    }

    return getSuppliers(page + 1, updatedSuppliers)
  }

  useEffect(() => {
    cancelled.current = false

    getSuppliers().catch()

    return () => {
      cancelled.current = true
    }
  }, [dispatch])

  return suppliers
}
