import React, { useCallback, useMemo, useState } from "react";
import {
  Redirect,
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useApplicationContext } from "@commercetools-frontend/application-shell-connectors";
import { CustomFormModalPage } from "@commercetools-frontend/application-components";
import { ExportIcon } from "@commercetools-uikit/icons";
import PrimaryButton from "@commercetools-uikit/primary-button";
import LoadingSpinner from "@commercetools-uikit/loading-spinner";
import Text from "@commercetools-uikit/text";
import { Product } from "@commercetools/platform-sdk";
import { DOMAINS } from "@commercetools-frontend/constants";
import { useShowNotification } from "@commercetools-frontend/actions-global";
import { ProductRange } from "@moonpig/studio-product-personalisation-types";
import Spacings from "@commercetools-uikit/spacings";
import { useProductMappings } from "./useProductMappings";
import { Notification } from "./Notification";
import style from "./ProductView.mod.css";
import { mapInvalidMappingResult } from "../../../services/validationResultMapper";
import {
  isDuplicateKeyResult,
  isInvalidMappingResult,
  MappingApiResponse,
  MappingError,
} from "../../../types";
import { ProductActionDropdown } from "../ProductActionDropdown";
import { getShape, getShapeAttribute } from "../../../utils/shape";
import { ProductImageSceneCollectionSelector } from "./ProductImageSceneCollectionSelector";
import { TemplateMapping } from "./TemplateMapping";
import { useProductRangeFetcher } from "../../../hooks/connectors/use-product-ranges-connector";
import { useProductTypeSettingsFetcher } from "../../../hooks/connectors/use-product-type-settings-connector";
import { useProduct } from "../../../hooks/connectors/use-product-connector";
import { useApplicationUrl } from "../../../hooks/useApplicationUrl";

type ProductMappingProps = {
  product: Product;
  duplicated: boolean;
  defaultMapping: Partial<MappingApiResponse>;
  productRange?: Partial<
    Pick<ProductRange, "stickerSets" | "profanityFilter" | "aiStickers">
  >;
};

export const ProductMapping: React.FC<ProductMappingProps> = ({
  product,
  duplicated,
  defaultMapping,
  productRange,
}) => {
  const history = useHistory();
  const showNotification = useShowNotification();

  const { projectKey, entryPointUriPath } = useApplicationContext(
    (context) => ({
      projectKey: context.project?.key ?? "",
      entryPointUriPath: context.environment.entryPointUriPath,
    })
  );

  const productTypeKey = product.productType?.obj?.key || "";
  const { data: productTypeSettings } = useProductTypeSettingsFetcher({
    productTypeKey,
  });

  const {
    productMapping,
    setProductMapping,
    saveProductMapping,
    isFetching,
    keyUsage,
  } = useProductMappings(product.key as string, defaultMapping);

  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<MappingError[]>([]);
  const [emptyField, setEmptyField] = useState(false);

  const onSavedSuccessfully = () => {
    showNotification({
      kind: "success",
      domain: DOMAINS.SIDE,
      text: `Product mapping has been saved successfully!`,
    });
  };

  const onSaveFailed = () => {
    showNotification({
      kind: "error",
      domain: DOMAINS.SIDE,
      text: `Product mapping failed to be saved!`,
    });
  };

  const getErrorsForTemplate = (index: number) => {
    return errors
      .filter((error) => error.index === index)
      .map((error) => error.message);
  };

  const onSave = useCallback(async () => {
    setSaving(true);
    try {
      const invalidProductMappings = productMapping.mapping.some(
        (m) => !m || !m.templateId || m.templateId === ""
      );

      if (!invalidProductMappings) {
        const result = await saveProductMapping({
          productId: product.key as string,
          masterVariantShape: getShape(getShapeAttribute(product)),
          commerceToolsId: product.id,
          commerceToolsVersion: product.version,
          productType: productTypeKey,
          stickerSet: productRange?.stickerSets,
          profanityFilter: productRange?.profanityFilter,
          aiStickers: productRange?.aiStickers,
          created: productMapping.created,
          updated: productMapping.updated,
          lastUpdated: new Date().toISOString(),
          productImageSceneCollectionId:
            productMapping.productImageSceneCollectionId,
          mapping: productMapping.mapping.map((m) => ({
            index: m.index,
            templateId: m.templateId,
            copies: m.copies,
          })),
        });

        if (isInvalidMappingResult(result)) {
          const mappingErrors = mapInvalidMappingResult(result);
          setErrors(mappingErrors);

          onSaveFailed();
        } else if (isDuplicateKeyResult(result)) {
          setErrors([
            {
              message:
                "This mapping cannot be saved because there is one or more other products using the same key",
            },
            ...result.keys.map((key) => ({ message: key })),
          ]);
          onSaveFailed();
        } else {
          setErrors([]);

          onSavedSuccessfully();
        }
      }

      setEmptyField(invalidProductMappings);
      setSaving(false);
    } catch (error) {
      setErrors([
        {
          message:
            "Something has gone wrong with your last action, please contact @studio-help on Slack",
        },
      ]);
      setSaving(false);

      onSaveFailed();
    }
  }, [productMapping]);

  const goToProduct = () => {
    history.push(`/${projectKey}/products/${product.id}`);
  };

  const downloadPdf = () => {
    if (productMapping?.pdfUrl) {
      window.open(productMapping.pdfUrl, "_blank", "noopener,noreferrer");
    }
  };

  const onClose = () => {
    history.push(
      generatePath("/:projectKey/:entryPointUriPath/product-mappings", {
        projectKey,
        entryPointUriPath,
      })
    );
  };

  const printPdf = () => {
    if (product.key === undefined) return;

    history.push(
      generatePath(
        "/:projectKey/:entryPointUriPath/product-mappings/:productKey/print-pdf",
        {
          projectKey,
          entryPointUriPath,
          productKey: product.key,
        }
      )
    );
  };

  const duplicateProduct = () => {
    if (product.key === undefined) return;

    history.push(
      generatePath(
        "/:projectKey/:entryPointUriPath/product-mappings/:productKey/duplicate",
        {
          projectKey,
          entryPointUriPath,
          productKey: product.key,
        }
      )
    );
  };

  const header = (
    <div className={style["std-c-product-header"]}>
      <div className={style["std-c-product-header__notification-group"]}>
        {duplicated && (
          <Notification type="warning">
            This is a duplicated product
          </Notification>
        )}
        {errors.length > 0 && (
          <Notification
            type="error"
            onClose={() => setErrors([])}
            dismissable={false}
          >
            <div>
              <span>There was a problem mapping these templates:</span>
              <ul className={style["std-c-product-header__mapping-error-list"]}>
                {errors
                  .filter((error) => error.index === undefined)
                  .map((error, i) => (
                    <li key={i}>{error.message}</li>
                  ))}
              </ul>
            </div>
          </Notification>
        )}
        {keyUsage?.isDuplicate && (
          <Notification type="error" dismissable={false}>
            <div>
              <p>
                This product is using the same key as one or more other products
                in the system.
              </p>
              <p>
                We can only support one product using a particular key
                (regardless of case).
              </p>
              <div>
                The keys that are currently being used are:
                <ul
                  className={style["std-c-product-header__mapping-error-list"]}
                >
                  {keyUsage.usages.map((key, i) => (
                    <li key={i}>{key}</li>
                  ))}
                </ul>
              </div>
            </div>
          </Notification>
        )}
        {emptyField && (
          <Notification type="warning" onClose={() => setEmptyField(false)}>
            One or more required fields are empty
          </Notification>
        )}
      </div>
      <div className={style["std-c-product-header__action-group"]}>
        <div className={style["std-c-product-header__dropdown"]}>
          <ProductActionDropdown
            handleGoToProduct={goToProduct}
            handleDuplicateProduct={duplicateProduct}
            allowDuplicateProduct={!duplicated}
            allowProductPdf={productMapping?.actions?.canDownloadPDF ?? false}
            hasRenderedProof={
              productMapping?.actions?.canCreateTestProof ?? false
            }
            handleProductPdf={downloadPdf}
            handlePrintPdf={printPdf}
          />
        </div>
        <div className={style["std-c-product-header__cta"]}>
          <PrimaryButton
            iconLeft={saving ? <LoadingSpinner scale="s" /> : <ExportIcon />}
            label="Save"
            onClick={onSave}
            isDisabled={saving || keyUsage?.isDuplicate}
            size="20"
            style={{ minWidth: "106px" }}
          />
        </div>
      </div>
    </div>
  );

  const sceneCollectionChanged = (sceneCollectionId?: string) => {
    setProductMapping({
      ...productMapping,
      productImageSceneCollectionId: sceneCollectionId,
    });
  };

  const updatedDate = useMemo(() => {
    if (!productMapping?.updated?.at) return "";
    return new Date(productMapping.updated.at).toLocaleString();
  }, [productMapping]);

  return (
    <CustomFormModalPage
      isOpen
      onClose={onClose}
      title={
        (product.masterData.staged.name?.en ??
          product.masterData.staged.name?.nl) ||
        ""
      }
      subtitle={
        <>
          <div className={style["std-c-product-mapping-modal_subtitle"]}>
            <Text.Subheadline as="h4">
              {product.productType?.obj?.name || ""}
            </Text.Subheadline>
            {productMapping?.updated?.at && (
              <Text.Detail>
                Last Updated {updatedDate} by {productMapping.updated.by}
              </Text.Detail>
            )}
          </div>
        </>
      }
      topBarCurrentPathLabel={product.key as string}
      topBarPreviousPathLabel="Back"
    >
      {isFetching ? (
        <LoadingSpinner scale="l">
          <Text.Headline as="h2">Loading templates...</Text.Headline>
        </LoadingSpinner>
      ) : (
        <>
          {header}
          <Spacings.Stack>
            <ProductImageSceneCollectionSelector
              productMapping={productMapping}
              productTypeKey={productTypeKey}
              onSceneCollectionSelected={sceneCollectionChanged}
            />
            <TemplateMapping
              productMapping={productMapping}
              emptyField={emptyField}
              setProductMapping={setProductMapping}
              getErrorsForTemplate={getErrorsForTemplate}
              canSetCopies={productTypeSettings?.canHaveMultipleCopies ?? false}
            />
          </Spacings.Stack>
        </>
      )}
    </CustomFormModalPage>
  );
};

export const ProductMappingContainer: React.FC = (props) => {
  const { url } = useApplicationUrl();
  const match = useRouteMatch();
  const location = useLocation();
  const [duplicatedFromKey] = useState<string | undefined>(
    () =>
      new URLSearchParams(location.search).get("duplicatedFrom") ?? undefined
  );

  const params = useParams<{ productKey: string }>();

  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useProduct({
    productKey: params.productKey,
  });

  const { productMapping, isFetching } = useProductMappings(
    duplicatedFromKey as string,
    {}
  );

  const defaultMapping = useMemo(() => {
    if (productMapping.mapping.length > 0) {
      return {
        ...productMapping,
        mapping: [
          {
            templateId: "",
            index: 1,
            templateData: undefined,
            publishDate: undefined,
            copies: 1,
          },
          ...productMapping.mapping.filter((m) => m.index !== 1),
        ],
      };
    }

    return productMapping;
  }, [productMapping, isFetching]);

  const rangeAttribute = useMemo(() => {
    if (!productData || productLoading === true) return undefined;

    return productData.masterData.staged.masterVariant?.attributes?.find(
      (a) => a.name === "product-range"
    );
  }, [productData, productLoading]);

  const { data: rangeData, loading: rangeLoading } = useProductRangeFetcher({
    id: productData ? rangeAttribute?.value.id : null,
  });

  const isLoading = productLoading || rangeLoading || isFetching;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (productError !== undefined) {
    return (
      <Redirect
        exact
        from={`${match.path}`}
        to={url(`product-mappings?missingProduct=${params.productKey}`)}
      />
    );
  }

  const isReady =
    productError === undefined &&
    productData !== undefined &&
    (rangeAttribute === undefined ? true : rangeData !== undefined) &&
    (duplicatedFromKey !== undefined
      ? productMapping !== undefined && isFetching === false
      : true);

  return (
    <>
      {isReady && (
        <ProductMapping
          duplicated={duplicatedFromKey !== undefined}
          defaultMapping={defaultMapping}
          product={productData}
          productRange={{
            stickerSets: rangeData?.stickerSets,
            profanityFilter: rangeData?.profanityFilter,
            aiStickers: rangeData?.aiStickers,
          }}
          {...props}
        />
      )}
    </>
  );
};
