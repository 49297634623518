import { entryPointUriPathToPermissionKeys } from '@commercetools-frontend/application-shell/ssr'
import config from '../custom-application-config.json'

export const PERMISSIONS = entryPointUriPathToPermissionKeys(
  config.entryPointUriPath,
)
export const IMAGE_THUMBNAIL_PLACEHOLDER_BASE64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMEAAACgCAYAAAC16ghjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASNSURBVHgB7d2/UhNbAMfx9c4ttddeH8Bee7UGeuh5AKCHB6CHHnqGnvQ8APTQQ8+dk2G9AfIfNpzk9/nMMElU1Mzsl+zZPbvnQ6/Xe2gg2D8NhBMB8URAPBEQTwTEEwHxREA8ERBPBMQTAfFEQDwREE8ExBMB8URAPBEQTwTEEwHxREA8ERBPBMQTAfFEQDwREE8ExBMB8URAPBEQTwTEEwHxREA8ERBPBMQTAfFEQDwREE8ExBMB8URAPBEQTwTEEwHxREA8ERBPBMQTAfFEQDwREE8ExBMB8URAPBEQ79+GFy4vL5uLi4vm5uamub+/b5bdt2/fmvX19ebLly8NL33o9XoPDX8dHR31v1bR1tZW/4un7A4NODs7W9kAivLeynvkKREMWOUAWicnJw1PieBR2fe/vb1tVt319XV/rMP/DIwfDRsAf//+vdnd3W2WWfn/X11dNYwmggmW/YjKx48fG8azO0Q8ERBPBMQTwRIrhzt//frVrK2tRRze7YqB8Svd3d31jyyVAeinT5+aRRk8s13+/fa5M8Kz80nwCu1h1XIEqTxf1KHIUVM7VnnKR5dEMKcyyW7wp38JoXx1HcKkDV0IsxPBnIadP+h6l2jaDVwIszEmeGMPD91Myp11wxbB9EQwpzIgLj/5F3FGdlwA7bSO/f39od/njPFkIphTuVCl3f8vG1oZGLfjgrc0KYA/f/78fT0shFW4KKhrIniFEkKXZgmgfT4sBMYzMK7ULAG0yq8t+6zX9yCCCs0TQEsIsxNBZV4TQEsIsxFBRd4igJYQpieCSrxlAK3yPeXqOMYTQQW6CIDpieCdCeD9ieAdCaAOIniFcuuS7e3t5sePH/3HWW5lIoB6iGBObQBlSnVRHqcNQQB1EcEc2gCe36yrvJ4UggDqI4IZjQqgNS4EAdRJBDOYFEBrWAgCqJcIpjRtAK3BEARQNxFMqUxRHhbA169fm+Pj4+bz588vfq/8+c3NTQFUTgRTGhXA4eFh/7qC8jgshFEXtQigHiKY0u/fv5+8bgMYvNvEqBCeE0BdXFk2pXJTq3IZZVnLrExKK2uAPb+zRBvCuLFD+XsEUBefBDPY2Njob+RlQx51a5VJnwhluSSLZNRFBB0YF8I0J9RYLBF0RAjLQwQdEsJyEEHHhFA/ESyAEOomggURQr1EsEBCqJMIFkwI9RHBOxBCXUTwToRQD3OHxihrEHS9Ie7t7TU7OzsvZpu2IRwcHFhjoGMfer1eN0urLJmy0ZWlUBOcn58La4DdoUdlt6Tr9QZqUN6nAJ4SwYAyPXrVWef4JREMKPP8V3kjcS3DcMYEQ5TB8Onp6cIW5+5S2fUpR6J+/vzpDtUjiIB4doeIJwLiiYB4IiCeCIgnAuKJgHgiIJ4IiCcC4omAeCIgngiIJwLiiYB4IiCeCIgnAuKJgHgiIJ4IiCcC4omAeCIgngiIJwLiiYB4IiCeCIgnAuKJgHgiIJ4IiCcC4omAeCIgngiIJwLiiYB4IiCeCIgnAuKJgHgiIJ4IiCcC4omAeCIgngiIJwLiiYB4IiDefzFrEzYytJ31AAAAAElFTkSuQmCC'
export const DEFAULT_LOCALE = config.additionalEnv.defaultLocale as string
export const MAPPING_API_HOST = config.additionalEnv.mappingApiHost
export const RANGE_PARENT_CATEGORY_ID =
  config.additionalEnv.rangeParentCategoryId
export const MAX_INVENTORY_AMOUNT = 2147483640
export const PRODUCT_KEY_VALIDATION_EXPRESSION = /^[\w-]+$/
export const PRODUCT_KEY_VALIDATION_MESSAGE =
  'Product Key contains invalid characters. Only letters, numbers and dashes (-) are allowed.'

export const FEATURE_FLAGS = config.additionalEnv.featureFlags
