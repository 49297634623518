import { actions, useAsyncDispatch } from '@commercetools-frontend/sdk'
import { useEffect, useState } from 'react'
import { StickerSet } from '@moonpig/studio-product-personalisation-types'
import { MAPPING_API_HOST } from '../constants'
import { getProjectKey } from '../services'

const isStickerSetResponse = (response: unknown): response is StickerSet[] => {
  return !!response && Array.isArray(response)
}

export const useStickerSets = () => {
  const [stickerSets, setStickerSets] = useState<StickerSet[]>([])
  const dispatch = useAsyncDispatch()

  const getStickerSets = async () => {
    const getRequest = actions.get({
      uri: '/proxy/forward-to',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-Forward-To': `${MAPPING_API_HOST}/sticker-set`,
        'X-Project-Key': getProjectKey(),
        'Accept-version': 'v2',
      },
    })

    const result = await dispatch(getRequest)
    if (isStickerSetResponse(result)) {
      setStickerSets(result)
    } else {
      setStickerSets([])
    }
  }

  useEffect(() => {
    getStickerSets()
  }, [dispatch])

  return {
    stickerSets,
  }
}
