import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  FormDetailPage,
  useModalState,
} from '@commercetools-frontend/application-components'
import { ProductType } from '@commercetools/platform-sdk'
import { DOMAINS } from '@commercetools-frontend/constants'
import { useShowNotification } from '@commercetools-frontend/actions-global'
import {
  ProductImageScene,
  ProductImageSceneCollection,
} from '@moonpig/studio-product-personalisation-types'
import { useApplicationUrl } from '../../hooks/useApplicationUrl'
import { ProductImageScenesForm } from './components/product-image-scenes-form'
import { useProductImageSceneForm } from './hooks/use-product-image-scene-form'
import { useProductImageSceneCollectionDetailsFetcher } from '../../hooks/connectors/use-product-image-scenes-connector'
import { useProductTypes } from '../../hooks/useProductServices'
import { ProductImageScenesConfirmation } from './components/product-image-scenes-confirmation'
import { useApplicationUser } from '../../hooks/useApplicationUser'

type ProductImageScenesDetailsProps = {
  productTypes: ProductType[]
  data?: {
    productImageSceneCollection?: ProductImageSceneCollection
    productImageScenes?: ProductImageScene[]
    productsUsingProductImageSceneCollection?: string[]
  }
}

export const ProductImageScenesDetails = (
  props: ProductImageScenesDetailsProps,
) => {
  const history = useHistory()
  const params = useParams<{ id: string }>()

  const confirmationModal = useModalState()
  const showNotification = useShowNotification()
  const { url } = useApplicationUrl()
  const { user } = useApplicationUser()

  const handleSubmitted = (type: 'created' | 'updated', id: string) => {
    showNotification({
      kind: 'success',
      domain: DOMAINS.SIDE,
      text: `Product Image Scene Collection has been ${type} successfully!`,
    })

    history.push(url(`product-image-scenes/${id}`))
  }

  const handleFailed = (type: 'created' | 'updated') => {
    showNotification({
      kind: 'error',
      domain: DOMAINS.SIDE,
      text: `Product Image Scene Collection failed to be ${type}.`,
    })
  }

  const form = useProductImageSceneForm({
    values: {
      id: props.data?.productImageSceneCollection?.id ?? undefined,
      name: props.data?.productImageSceneCollection?.name ?? '',
      productType: props.data?.productImageSceneCollection?.productType ?? '',
      scenes:
        props.data?.productImageSceneCollection?.productImageSceneIds?.map(
          scene => {
            const productImageScene = props.data?.productImageScenes?.find(
              imageScene => imageScene.id === scene,
            )

            return {
              id: scene,
              status: 'current',
              name: productImageScene?.name,
              preview: productImageScene?.previewImageKey,
            }
          },
        ) ?? [],
      actor: user?.email as string,
    },
    onSubmitted: (type: 'created' | 'updated', id: string) =>
      handleSubmitted(type, id),
    onFailed: (type: 'created' | 'updated') => handleFailed(type),
  })

  const handleSubmitting = () => {
    const products = props.data?.productsUsingProductImageSceneCollection ?? []

    if (products.length >= 25) {
      confirmationModal.openModal()

      return
    }

    form.formik.handleSubmit()
  }

  const handleConfirmation = () => {
    form.formik.handleSubmit()

    confirmationModal.closeModal()
  }

  const title = params.id
    ? 'Update Product Image Scene'
    : 'Create Product Image Scene'

  return (
    <FormDetailPage
      title={title}
      subtitle={'Manage the fields for the product image scene collection'}
      isPrimaryButtonDisabled={form.formik.isSubmitting || !form.formik.dirty}
      isSecondaryButtonDisabled={!form.formik.dirty}
      onSecondaryButtonClick={() => form.formik.resetForm()}
      onPrimaryButtonClick={() => handleSubmitting()}
      labelPrimaryButton="Save Product Image Scene"
      labelSecondaryButton="Revert Changes"
      onPreviousPathClick={() => history.push(url('product-image-scenes'))}
    >
      {form.formik && (
        <>
          {confirmationModal.isModalOpen && (
            <ProductImageScenesConfirmation
              count={
                props.data?.productsUsingProductImageSceneCollection?.length ??
                0
              }
              onClose={confirmationModal.closeModal}
              onConfirm={() => handleConfirmation()}
            />
          )}

          <ProductImageScenesForm
            productTypes={props.productTypes}
            formik={form.formik}
          />
        </>
      )}
    </FormDetailPage>
  )
}

const NewProductImageScene = (props: { productTypes: ProductType[] }) => {
  return (
    <ProductImageScenesDetails
      productTypes={props.productTypes}
      data={{
        productImageSceneCollection: undefined,
        productImageScenes: [],
        productsUsingProductImageSceneCollection: [],
      }}
    />
  )
}

const WithProductImageScene = (props: {
  id: string
  productTypes: ProductType[]
}) => {
  const fetcher = useProductImageSceneCollectionDetailsFetcher({ id: props.id })

  if (fetcher.loading) {
    return null
  }

  if (!fetcher.data) {
    return null
  }

  return (
    <ProductImageScenesDetails
      productTypes={props.productTypes}
      data={fetcher.data}
    />
  )
}

export const ProductImageScenesDetailsContainer = () => {
  const params = useParams<{ id: string }>()
  const { loading, data, error } = useProductTypes()

  if (loading) return null
  if (error) return null
  if (!data) return null

  if (params.id === undefined) {
    return <NewProductImageScene productTypes={data} />
  }

  return <WithProductImageScene productTypes={data} id={params.id} />
}
