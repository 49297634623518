import TextInput from '@commercetools-uikit/text-input'
import { FormikHelpers, useFormik } from 'formik'
import { useCallback } from 'react'
import {
  CreateProductImageSceneCollectionInput,
  UpdateProductImageSceneCollectionInput,
  useCreateProductImageSceneCollection,
  useProductImageSceneSignedUrlFetcher,
  useProductImageSceneUploader,
  useUpdateProductImageSceneCollection,
} from '../../../hooks/connectors/use-product-image-scenes-connector'

export type ZipFileValue = {
  id: string
  zipFile: File
  preview: string
}

export type SceneFormValue = (
  | {
      status: 'new'
      zip: File
    }
  | {
      status: 'current'
    }
) & { id: string; name?: string; preview?: string }

type ValidationError = {
  name?: { missing?: boolean }
  productType?: { missing?: boolean }
  scenes?: { missing?: boolean }
}

type ProductImageSceneFormValues = {
  id?: string
  name: string
  productType: string
  scenes: SceneFormValue[]
  actor: string
}

export type ProductImageSceneForm = ReturnType<
  typeof useFormik<ProductImageSceneFormValues>
>

const validate = (formikValues: ProductImageSceneFormValues) => {
  const errors: ValidationError = {}

  if (TextInput.isEmpty(formikValues.name)) {
    errors.name = { missing: true }
  }

  if (
    formikValues.productType === '' ||
    (Array.isArray(formikValues.productType) &&
      formikValues.productType.length === 0)
  ) {
    errors.productType = { missing: true }
  }

  if (formikValues.id === undefined && formikValues.scenes.length === 0) {
    errors.scenes = { missing: true }
  }

  return errors
}

const toCreatePayloadInput = (
  values: ProductImageSceneFormValues,
): CreateProductImageSceneCollectionInput => {
  return {
    name: values.name,
    productType: values.productType,
    scenes: values.scenes.map(scene => ({
      id: scene.id,
      status: scene.status,
    })),
    actor: values.actor,
  }
}

const toUpdatePayloadInput = (
  values: ProductImageSceneFormValues,
): UpdateProductImageSceneCollectionInput => {
  return {
    id: values.id as string,
    name: values.name,
    scenes: values.scenes.map(scene => ({
      id: scene.id,
      status: scene.status,
    })),
    actor: values.actor,
  }
}

type ProductImageSceneFormProps = {
  values: ProductImageSceneFormValues
  onSubmitted: (type: 'created' | 'updated', id: string) => void
  onFailed: (type: 'created' | 'updated') => void
}

export const useProductImageSceneForm = (props: ProductImageSceneFormProps) => {
  const createProductImageSceneCollection =
    useCreateProductImageSceneCollection()
  const updateProductImageSceneCollection =
    useUpdateProductImageSceneCollection()

  const signedUrl = useProductImageSceneSignedUrlFetcher()
  const assets = useProductImageSceneUploader()

  const handleSubmit = useCallback(
    async (
      formikValues: ProductImageSceneFormValues,
      formikHelpers: FormikHelpers<ProductImageSceneFormValues>,
    ) => {
      try {
        const promises = Array.from(formikValues.scenes)
          .filter(scene => scene.status === 'new')
          .map(async file => {
            await signedUrl
              .getSignedUrl(`${file.id}.zip`)
              .then(async response => {
                if (response.type === 'success') {
                  if (file.status === 'new') {
                    const uploadFile = new File([file.zip], `${file.id}.zip`, {
                      type: file.zip.type,
                    })

                    await assets.upload(uploadFile, response.uploadUrl)
                  }
                } else {
                  throw new Error('Error fetching signed URL')
                }
              })
          })

        await Promise.all(promises)

        const response = formikValues.id
          ? await updateProductImageSceneCollection.action(
              toUpdatePayloadInput(formikValues),
            )
          : await createProductImageSceneCollection.action(
              toCreatePayloadInput(formikValues),
            )

        if (response.type === 'success') {
          formikHelpers.resetForm({
            values: {
              ...formikValues,
              scenes: formikValues.scenes.map(scene => {
                return {
                  ...scene,
                  status: 'current',
                } as SceneFormValue
              }),
            },
          })

          props.onSubmitted(
            formikValues.id ? 'updated' : 'created',
            response.imageSceneCollectionId,
          )
        } else if (response.type === 'validation') {
          const errors = response.messages.reduce((acc, current) => {
            acc[current.field] = { [current.reason]: true }
            return acc
          }, {} as Record<string, Record<string, boolean>>)

          formikHelpers.setErrors(errors)

          props.onFailed(formikValues.id ? 'updated' : 'created')
        } else {
          props.onFailed(formikValues.id ? 'updated' : 'created')
        }
      } catch (e) {
        props.onFailed(formikValues.id ? 'updated' : 'created')
      }
    },
    [],
  )

  const formik = useFormik<ProductImageSceneFormValues>({
    initialValues: props.values,
    validate,
    onSubmit: (values, helpers) => handleSubmit(values, helpers),
    enableReinitialize: false,
  })

  return { formik }
}
