import { Attribute, Product } from '@commercetools/platform-sdk'

export const getAttributeFromProduct = (
  attributeName: string,
  product: Product | null,
): Attribute | undefined => {
  return product?.masterData.staged.masterVariant?.attributes?.find(
    (productAttribute: Attribute) => productAttribute.name === attributeName,
  )
}
