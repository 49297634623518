import { actions, useAsyncDispatch } from '@commercetools-frontend/sdk'
import { useState } from 'react'
import { MAPPING_API_HOST } from '../../../constants'
import { getProjectKey } from '../../../services'
import { isSearchResult, SearchApiResponseResult } from '../../../types'

type CacheResponse = Record<string, SearchApiResponseResult[]>
const cacheResponse: CacheResponse = {}

export const useTemplateSearch = ({
  setResult,
  onError,
}: {
  setResult: (result: SearchApiResponseResult[]) => void
  onError: () => void
}) => {
  const [error, setError] = useState(false)
  const [searching, setSearching] = useState(false)
  const dispatch = useAsyncDispatch()

  const searchAndCache = async (searchString: string) => {
    const getRequest = actions.get({
      uri: '/proxy/forward-to',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-Forward-To': `${MAPPING_API_HOST}/templates?searchTerm=${searchString}`,
        'X-Project-Key': getProjectKey(),
        'Accept-version': 'v2',
      },
    })
    const searchResponse = await dispatch(getRequest)
    if (isSearchResult(searchResponse)) {
      cacheResponse[searchString] = searchResponse.results
      return searchResponse.results
    }
    return []
  }

  const searchFunc = async (searchString: string) => {
    setSearching(true)
    try {
      const searchResponse = cacheResponse[searchString]
        ? cacheResponse[searchString]
        : await searchAndCache(searchString)

      setSearching(false)
      setError(false)
      setResult(searchResponse)
      return searchResponse
    } catch (e) {
      setError(true)
      onError()
      return undefined
    }
  }

  return {
    search: searchFunc,
    error,
    searching,
  }
}
