import React from "react";
import DataTable from "@commercetools-uikit/data-table";
import Spacings from "@commercetools-uikit/spacings";
import {
  BinLinearIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@commercetools-uikit/icons";
import PrimaryButton from "@commercetools-uikit/primary-button";
import SecondaryButton from "@commercetools-uikit/secondary-button";
import { SceneFormValue } from "../hooks/use-product-image-scene-form";

const columns = [
  { key: "index", label: "Index" },
  { key: "name", label: "Name" },
  { key: "preview", label: "Preview" },
  { key: "actions", label: "Actions" },
];

type ProductImageScenesFormScenesTableProps = {
  scenes: SceneFormValue[];
  onScenesChange: (scenes: SceneFormValue[]) => void;
};

export const ProductImageScenesFormScenesTable: React.FC<
  ProductImageScenesFormScenesTableProps
> = (props) => {
  const moveZipPositionInArray = (item: SceneFormValue, delta: number) => {
    const newRows = [...props.scenes];
    const index = newRows.indexOf(item);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === newRows.length) return;
    const indexes = [index, newIndex].sort((a, b) => a - b);
    newRows.splice(indexes[0], 2, newRows[indexes[1]], newRows[indexes[0]]);

    props.onScenesChange(newRows);
  };

  const handleDeleteZip = (index: number) => {
    const newRows = [...props.scenes];
    newRows.splice(index, 1);

    props.onScenesChange(newRows);
  };

  const handleMoveZipUp = (item: SceneFormValue) => {
    moveZipPositionInArray(item, -1);
  };

  const handleMoveZipDown = (item: SceneFormValue) => {
    moveZipPositionInArray(item, 1);
  };

  if (props.scenes.length === 0) {
    return null;
  }

  return (
    <DataTable
      rows={props.scenes}
      columns={columns}
      itemRenderer={(item, column) => {
        const itemIndex = props.scenes.indexOf(item);
        const canMoveUp = itemIndex !== 0;
        const canMoveDown = itemIndex !== props.scenes.length - 1;

        switch (column.key) {
          case "index":
            return <div key={`${item.id}zip`}>{itemIndex + 1}</div>;
          case "preview":
            return (
              <>
                {item.preview && (
                  <img
                    alt={`Preview for ${item.name}`}
                    width="150"
                    key={`${item.id}preview`}
                    src={item.preview}
                  ></img>
                )}
              </>
            );
          case "name":
            return <div key={`${item.id}-zip`}>{item.name}</div>;
          case "actions":
            return (
              <Spacings.Inline scale="m">
                <PrimaryButton
                  iconLeft={<BinLinearIcon />}
                  label={"Delete"}
                  tone={"critical"}
                  onClick={() => handleDeleteZip(itemIndex)}
                  size="10"
                />

                <SecondaryButton
                  label={"Up"}
                  iconLeft={<ArrowUpIcon />}
                  isDisabled={!canMoveUp}
                  onClick={() => handleMoveZipUp(item)}
                  size="10"
                />
                <SecondaryButton
                  label={"Down"}
                  iconLeft={<ArrowDownIcon />}
                  isDisabled={!canMoveDown}
                  onClick={() => handleMoveZipDown(item)}
                  size="10"
                />
              </Spacings.Inline>
            );
          default:
            return null;
        }
      }}
    />
  );
};
