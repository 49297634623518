import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ErrorMessage } from "@commercetools-uikit/messages";
import TextInput from "@commercetools-uikit/text-input";
import Text from "@commercetools-uikit/text";
import { Pagination } from "@commercetools-uikit/pagination";
import DataTable, { TColumn, TRow } from "@commercetools-uikit/data-table";
import { useDebouncedCallback } from "use-debounce";
import IconButton from "@commercetools-uikit/icon-button";
import { AngleRightIcon, WarningIcon } from "@commercetools-uikit/icons";
import SecondaryButton from "@commercetools-uikit/secondary-button";
import Label from "@commercetools-uikit/label";
import Constraints from "@commercetools-uikit/constraints";
import { ProductProjection } from "@commercetools/platform-sdk";
import { DOMAINS } from "@commercetools-frontend/constants";
import { useShowNotification } from "@commercetools-frontend/actions-global";
import style from "./product-list.mod.css";
import { useProductSearch } from "../useProductSearch";
import { ProductResults, Row } from "../../../types";
import {
  DEFAULT_LOCALE,
  IMAGE_THUMBNAIL_PLACEHOLDER_BASE64,
  PRODUCT_KEY_VALIDATION_EXPRESSION,
  PRODUCT_KEY_VALIDATION_MESSAGE,
} from "../../../constants";
import { DuplicateProductModalContainer } from "../../duplicate-product/DuplicateProductModal";
import { NoResultsMessage } from "./NoResultsMessage";
import { ProductPrintModalContainer } from "../ProductPrint/ProductPrintModalContainer";
import { MainLayout } from "../../layouts/main-layout";
import { useApplicationUrl } from "../../../hooks/useApplicationUrl";
import { SupplierDropdown } from "../../ui/dropdowns/SupplierDropdown";
import { RangeDropdown } from "../Ranges/RangeDropdown";
import { ProductTypeDropdown } from "../ProductTypes/ProductTypeDropdown";
import { SearchErrorMessage } from "./SearchErrorMessage";
import { ProductMappingContainer } from "../ProductView/ProductMapping";

interface Search {
  offset: number;
  limit: number;
  term: string;
  fuzzy: boolean;
  sort: string;
  ascending: boolean;
  rangeIds: string[];
  productTypeIds: string[];
}

export function ProductList() {
  const history = useHistory();
  const { projectKey, url } = useApplicationUrl();
  const [results, setResults] = useState<ProductResults>();
  const [searchError, setSearchError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchParameters, setSearchParameters] = useState<Search>({
    limit: 20,
    offset: 0,
    term: "",
    fuzzy: false,
    sort: "variants.sku",
    ascending: false,
    productTypeIds: [],
    rangeIds: [],
  });
  const [selectedSupplierIds, setSelectedSupplierIds] = useState<string[]>([]);

  const { results: searchResults, hasError } = useProductSearch(
    searchParameters.term,
    searchParameters.limit,
    searchParameters.offset,
    searchParameters.fuzzy,
    searchParameters.sort,
    searchParameters.ascending,
    searchParameters.rangeIds,
    searchParameters.productTypeIds
  );

  useEffect(() => {
    setResults(searchResults);
    setSearchError(hasError);
  }, [searchResults, hasError]);

  const search = useCallback(async (input: string) => {
    const individualTerms = input.split(" ");
    const sanitisedTerms = individualTerms.map((term) => term.split("-")[0]);
    const joinedTerms = sanitisedTerms.join(" ");
    setLoading(true);
    setSearchParameters((s) => ({
      ...s,
      term: joinedTerms,
      offset: 0,
    }));
    setLoading(false);
  }, []);

  // Use debounced search for text based changes so every keystroke doesn't trigger a search
  const debouncedSearch = useDebouncedCallback(search, 1000);
  useEffect(() => {
    debouncedSearch(searchInput);
  }, [debouncedSearch, searchInput]);

  const initiateDuplicateProduct = (productId: string) => {
    history.push(url(`product-mappings/${productId}/duplicate`));
  };

  type ProductListRow = TRow &
    Partial<{
      keyIsValid: boolean;
      name: string;
      key: string;
      imageUrl: string;
    }>;

  const goToProduct = (product: ProductListRow) =>
    history.push(`/${projectKey}/products/${product.id}`);

  const dateLocale = DEFAULT_LOCALE === "en" ? "en-GB" : "nl-NL";

  const onSelectedProduct = (selected: ProductProjection) => {
    history.push(url(`product-mappings/${selected.key}`));
  };

  const rows: Row[] = useMemo(() => {
    if (searchError) return [];

    return loading
      ? []
      : results?.results?.map((product) => {
          const keyIsValid =
            (product.key &&
              PRODUCT_KEY_VALIDATION_EXPRESSION.test(product.key)) ??
            false;
          return {
            id: product.id,
            name: product.name[DEFAULT_LOCALE],
            type: product.productType?.obj?.name,
            key: product.key,
            keyIsValid,
            status: product.published ? "Published" : "Unpublished",
            createdAt: new Date(product.createdAt).toLocaleString([dateLocale]),
            lastModifiedAt: new Date(product.lastModifiedAt).toLocaleString([
              dateLocale,
            ]),
            sku: product.masterVariant?.sku,
            imageUrl: product.masterVariant?.images?.[0]?.url,
            button: (
              <IconButton
                data-testid={`map-product-${product.key}`}
                label={`Map product ${product.key}`}
                onClick={() => onSelectedProduct(product)}
                icon={<AngleRightIcon />}
                disabled={!keyIsValid}
                size="40"
              />
            ),
          };
        }) ?? [];
  }, [results, loading, dateLocale, searchError]);

  const columns: TColumn[] = [
    {
      key: "image",
      label: "Preview ",
      renderItem: (row: ProductListRow) => {
        return (
          <div className={style["std-c-product-row__image-container"]}>
            {!row.imageUrl && (
              <img
                alt="Thumbnail placeholder"
                className={style["std-c-product-row__image"]}
                src={IMAGE_THUMBNAIL_PLACEHOLDER_BASE64}
              />
            )}
            {row.imageUrl && (
              <img
                className={style["std-c-product-row__image"]}
                src={row.imageUrl}
                alt={row.name}
              />
            )}
          </div>
        );
      },
    },
    {
      key: "name",
      label: "Product name",
      isSortable: true,
      renderItem: (row: ProductListRow) => {
        return (
          <>
            <div>{row.name}</div>
            <div className={style["std-c-product-row__cta-container"]}>
              <SecondaryButton
                disabled={row?.key === undefined}
                label="Duplicate product"
                onClick={() => {
                  if (row?.key === undefined) return;

                  initiateDuplicateProduct(row?.key);
                }}
                data-testid={`duplicate-product-${row.key}`}
                size="10"
              />
            </div>
          </>
        );
      },
    },
    {
      key: "key",
      label: "Product key",
      renderItem: (row: ProductListRow) => {
        return (
          <div aria-label={`product-key-for-${row.id}`}>
            {row.key && (
              <>
                <div>{row.key}</div>
                {!row.keyIsValid && (
                  <div className={style["std-c-product-row__warning-message"]}>
                    <WarningIcon color="error" />
                    <ErrorMessage>
                      {PRODUCT_KEY_VALIDATION_MESSAGE}
                    </ErrorMessage>
                  </div>
                )}
              </>
            )}
            {!row.key && (
              <div className={style["std-c-product-row__warning-message"]}>
                <WarningIcon color="error" />
                <ErrorMessage>Product Key needed</ErrorMessage>
              </div>
            )}
            <div className={style["std-c-product-row__cta-container"]}>
              <SecondaryButton
                label="Go to product"
                onClick={() => {
                  goToProduct(row);
                }}
                size="10"
              />
            </div>
          </div>
        );
      },
    },
    { key: "sku", label: "SKU", isSortable: true },
    { key: "type", label: "Product type" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Created On", isSortable: true },
    { key: "lastModifiedAt", label: "Modified On", isSortable: true },
    { key: "button", label: "" },
  ];

  const handleSuppliersSelected = (supplierIds: string[]) => {
    setSelectedSupplierIds(supplierIds);
  };

  const handleRangeSelected = (rangeIds: string[]) => {
    setSearchParameters({
      ...searchParameters,
      rangeIds,
      offset: 0,
    });
  };

  const handleProductTypesSelected = (productTypeIds: string[]) => {
    setSearchParameters({
      ...searchParameters,
      productTypeIds,
      offset: 0,
    });
  };

  const sortColumnMappings: { [key: string]: string } = {
    name: `name.${DEFAULT_LOCALE}`,
    sku: "variants.sku",
  };

  const sortedBy = useMemo(() => {
    const mappedEntry = Object.entries(sortColumnMappings).find(
      (entry) => entry[1] === searchParameters.sort
    );

    if (mappedEntry) {
      return mappedEntry[0];
    }

    return searchParameters.sort;
  }, [searchParameters.sort]);

  const onSortChange = (columnKey: string, sortDirection: "asc" | "desc") => {
    let sort = columnKey;
    if (columnKey in sortColumnMappings) {
      sort = sortColumnMappings[columnKey];
    }

    setSearchParameters({
      ...searchParameters,
      sort,
      ascending: sortDirection === "asc",
    });
  };

  return (
    <MainLayout
      heading={<Text.Headline as="h1">Product Mappings</Text.Headline>}
    >
      <div className={style["std-c-search-header__container"]}>
        <div className={style["std-c-search-header__forms"]}>
          <Constraints.Horizontal max={6}>
            <Label>Term</Label>
            <TextInput
              className={style["std-c-search-header__search-form"]}
              value={searchInput}
              horizontalConstraint={6}
              isAutofocussed
              placeholder="Enter a product name or key"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchInput(event.target.value)
              }
            />
          </Constraints.Horizontal>
          <Constraints.Horizontal max={5}>
            <SupplierDropdown
              onSuppliersSelected={handleSuppliersSelected}
              isMulti={false}
              horizontalConstraint={5}
            />
          </Constraints.Horizontal>
          <Constraints.Horizontal max={"scale"}>
            <RangeDropdown
              onRangesSelected={handleRangeSelected}
              supplierIds={selectedSupplierIds}
              isMulti={true}
              horizontalConstraint={"scale"}
              maximumRangeSelection={250}
            />
          </Constraints.Horizontal>
          <Constraints.Horizontal max={6}>
            <ProductTypeDropdown
              onProductTypesSelected={handleProductTypesSelected}
              isMulti={true}
              horizontalConstraint={6}
            />
          </Constraints.Horizontal>
        </div>
      </div>

      {searchError && <SearchErrorMessage />}
      {loading || (rows && rows.length > 0) ? (
        <>
          <DataTable
            columns={columns}
            rows={rows}
            sortedBy={sortedBy}
            sortDirection={searchParameters.ascending ? "asc" : "desc"}
            onSortChange={onSortChange}
          />

          <Pagination
            totalItems={results?.total ?? 1}
            page={searchParameters.offset / searchParameters.limit + 1}
            onPageChange={(page: number) =>
              setSearchParameters({
                ...searchParameters,
                offset: (page - 1) * searchParameters.limit,
              })
            }
            perPage={searchParameters.limit}
            onPerPageChange={(perPage: number) =>
              setSearchParameters({
                ...searchParameters,
                offset: 0,
                limit: perPage,
              })
            }
          />
        </>
      ) : (
        <>{!loading && !searchError && <NoResultsMessage />}</>
      )}
    </MainLayout>
  );
}

export const ProductListContainer = () => {
  const match = useRouteMatch();
  const showNotification = useShowNotification();

  const location = useLocation();

  const missingProduct = useMemo(() => {
    return (
      new URLSearchParams(location.search).get("missingProduct") ?? undefined
    );
  }, [location.search]);

  useEffect(() => {
    if (missingProduct) {
      showNotification({
        kind: "error",
        domain: DOMAINS.SIDE,
        text: `Product with key "${missingProduct}" was not found!`,
      });
    }
  }, [missingProduct]);

  return (
    <>
      <ProductList />

      <Route
        exact
        path={`${match.path}/:productKey/duplicate`}
        render={(routerProps) => (
          <DuplicateProductModalContainer
            duplicateProductKey={routerProps.match.params.productKey}
          />
        )}
      />

      <Route
        exact
        path={[
          `${match.path}/:productKey`,
          `${match.path}/:productKey/print-pdf`,
        ]}
      >
        <ProductMappingContainer />
      </Route>

      <Route
        path={`${match.path}/:productKey/print-pdf`}
        render={(routerProps) => (
          <ProductPrintModalContainer
            productKey={routerProps.match.params.productKey}
          />
        )}
      />
    </>
  );
};
