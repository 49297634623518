import {
  TApplicationContext,
  useApplicationContext,
} from '@commercetools-frontend/application-shell-connectors'

type FeatureFlags = {
  readonly [key: string]: boolean
}

export const useFeatureFlags = () =>
  useApplicationContext(
    (context: TApplicationContext<{ featureFlags: FeatureFlags }>) => {
      return context.environment.featureFlags
    },
  )
