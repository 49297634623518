import React from 'react'
import Text from '@commercetools-uikit/text'

export function SearchErrorMessage() {
  return (
    <>
      <Text.Subheadline as="h4">
        Sorry, there was a problem with the search. Please check your filter
        settings and try again.
      </Text.Subheadline>
      <Text.Body as="span">
        <Text.Body>Suggestions:</Text.Body>
        <ul>
          <li>Make sure you have &lt; 250 ranges selected</li>
        </ul>
      </Text.Body>
      <Text.Body as="span">
        If you continue to have problems, please contact @studio-help
      </Text.Body>
    </>
  )
}
