import {
  TSdkActionGet,
  actions,
  useAsyncDispatch,
} from '@commercetools-frontend/sdk'
import {
  ProductDraft,
  ProductType,
  ProductProjection,
  Product,
} from '@commercetools/platform-sdk'
import { useEffect, useRef, useState } from 'react'
import { ProductNotFoundError } from '../components/duplicate-product/ProductNotFoundError'

export const useProductTypes = () => {
  const dispatch = useAsyncDispatch<TSdkActionGet, { results: ProductType[] }>()

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<ProductType[]>()
  const [error, setError] = useState<string>()

  const cancelRequest = useRef<boolean>(false)

  useEffect(() => {
    cancelRequest.current = false

    const getProductTypes = async () => {
      if (cancelRequest.current) return

      setLoading(true)

      try {
        const response = await dispatch(
          actions.get({
            service: 'productTypes',
            options: {
              perPage: 500,
              sort: [{ by: 'name', direction: 'asc' }],
            },
          }),
        )

        if (cancelRequest.current) return
        setData(response.results)
      } catch (e) {
        if (cancelRequest.current) return
        setError('Failed to fetch product types.')
      }

      if (cancelRequest.current) return
      setLoading(false)
    }

    getProductTypes()

    return () => {
      cancelRequest.current = true
    }
  }, [])

  return { loading, data, error }
}

export const useProductServices = () => {
  const dispatch = useAsyncDispatch()
  const [requestCancelled, setRequestCancelled] = useState<boolean>(false)

  const fetchProductByKey = async (
    key: string,
    expand: string[] = [],
  ): Promise<Product> => {
    async function execute() {
      try {
        return (await dispatch(
          actions.get({
            service: 'products',
            options: {
              key,
              expand,
            },
          }),
        )) as Product
      } catch (error: unknown) {
        if ((error as Error).name === 'NotFound') {
          throw new ProductNotFoundError(key)
        } else {
          throw error
        }
      }
    }

    return execute()
  }

  const fetchProductById = (
    productId: string,
    expand: string[] = [],
  ): Promise<Product> => {
    async function execute() {
      try {
        return (await dispatch(
          actions.get({
            service: 'products',
            options: {
              id: productId,
              expand,
            },
          }),
        )) as Product
      } catch (error: unknown) {
        if ((error as Error).name === 'NotFound') {
          throw new ProductNotFoundError(productId)
        } else {
          throw error
        }
      }
    }

    return execute()
  }

  const fetchProductProjection = async (
    productId: string,
    expandParams?: string[],
  ): Promise<ProductProjection | undefined> => {
    setRequestCancelled(false)

    async function execute() {
      return (await dispatch(
        actions.get({
          service: 'productProjections',
          options: {
            id: productId,
            staged: true,
            expand: expandParams,
          },
        }),
      )) as Promise<ProductProjection>
    }

    if (requestCancelled) return undefined

    return execute()
  }

  const fetchProductType = async (
    productTypeId: string,
    expandParams?: string[],
  ): Promise<ProductType | undefined> => {
    setRequestCancelled(false)

    async function execute() {
      try {
        return (await dispatch(
          actions.get({
            service: 'productTypes',
            options: {
              id: productTypeId,
              expand: expandParams,
            },
          }),
        )) as ProductType
      } catch (error) {
        console.log('Error fetching product types', error)
        throw error
      }
    }

    if (requestCancelled) return undefined
    return execute()
  }

  const createNewProduct = async (product: ProductDraft): Promise<Product> => {
    async function execute() {
      return (await dispatch(
        actions.post({
          service: 'products',
          options: {
            expand: ['productType'],
          },
          payload: product,
        }),
      )) as Promise<Product>
    }

    return execute()
  }

  return {
    fetchProductByKey,
    fetchProductById,
    fetchProductProjection,
    fetchProductType,
    createNewProduct,
    setRequestCancelled,
    requestCancelled,
  }
}
