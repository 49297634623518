import React, { FC, useEffect, useRef, useState } from 'react'
import { ProductPrintModal } from './ProductPrintModal'
import { useProductServices } from '../../../hooks'

type ProductPrintModalContainerProps = {
  productKey: string
}

export const ProductPrintModalContainer: FC<
  ProductPrintModalContainerProps
> = props => {
  const cancelled = useRef(false)

  const productServices = useProductServices()
  const [productKey, setProductKey] = useState<string>()
  const [productType, setProductType] = useState<string>()
  const [productVariants, setProductVariants] =
    useState<Array<{ sku: string; size?: string; shape?: string }>>()

  useEffect(() => {
    cancelled.current = false

    const fetchProduct = async () => {
      const response = await productServices.fetchProductByKey(
        props.productKey,
        ['productType'],
      )

      const variants = response.masterData.staged.variants.map(variant => {
        return {
          sku: variant.sku as string,
          size:
            variant.attributes?.find(attribute => attribute.name === 'size')
              ?.value.key ?? undefined,
          shape:
            variant.attributes?.find(attribute => attribute.name === 'shape')
              ?.value.key ?? undefined,
        }
      })

      variants.push({
        sku: response.masterData.staged.masterVariant.sku as string,
        size:
          response.masterData.staged.masterVariant.attributes?.find(
            attribute => attribute.name === 'size',
          )?.value.key ?? undefined,
        shape:
          response.masterData.staged.masterVariant.attributes?.find(
            attribute => attribute.name === 'shape',
          )?.value.key ?? undefined,
      })

      if (!cancelled.current) {
        setProductKey(response.key)
        setProductType(response.productType.obj?.key)
        setProductVariants(variants)
      }
    }

    fetchProduct()

    return () => {
      cancelled.current = true
    }
  }, [props.productKey])

  return (
    <>
      {productVariants && productKey && productType ? (
        <ProductPrintModal
          productVariants={productVariants}
          productKey={productKey}
          productType={productType}
        />
      ) : null}
    </>
  )
}
