import { useEffect, useRef, useState } from 'react'
import {
  useAsyncDispatch,
  actions,
  TSdkActionGet,
} from '@commercetools-frontend/sdk'
import {
  Category,
  CategoryPagedQueryResponse,
} from '@commercetools/platform-sdk'
import { RANGE_PARENT_CATEGORY_ID } from '../../constants'

type CategoryInput = {
  id: string
}

export const useCategory = (input: CategoryInput) => {
  const cancelled = useRef<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Category>()

  const dispatch = useAsyncDispatch<TSdkActionGet, Category>()

  useEffect(() => {
    cancelled.current = false

    const action = async () => {
      if (!cancelled.current) {
        setLoading(true)
      }

      try {
        const response = await dispatch(
          actions.get({
            service: 'categories',
            options: {
              id: input.id,
            },
          }),
        )

        if (!cancelled.current) {
          setLoading(false)
          setData(response)
        }
      } catch (e) {
        console.log(e)
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, [input.id])

  return {
    data,
    loading,
  }
}

type RangeCategoriesInput = {
  page: { value: number }
  perPage: { value: number }
  sort: {
    key: string
    order: 'desc' | 'asc'
  }
  filters: { name?: string; supplierIds?: string[] }
}

export const useRangeCategories = (input: RangeCategoriesInput) => {
  const cancelled = useRef<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<CategoryPagedQueryResponse>()

  const dispatch = useAsyncDispatch<TSdkActionGet, CategoryPagedQueryResponse>()

  useEffect(() => {
    cancelled.current = false

    const action = async () => {
      if (!cancelled.current) {
        setLoading(true)
      }

      try {
        const where = [`parent(id="${RANGE_PARENT_CATEGORY_ID}")`]

        if (input.filters.name) {
          where.push(
            `name(en="${input.filters.name}") or name(nl="${input.filters.name}") or key="${input.filters.name}"`,
          )
        }

        if (
          Array.isArray(input.filters.supplierIds) &&
          input.filters.supplierIds.length > 0
        ) {
          const whereRangeSupplierIdEquals = input.filters.supplierIds.map(
            id => `custom(fields(rangeSupplierId(id="${id}")))`,
          )

          where.push(whereRangeSupplierIdEquals.join('or '))
        }

        const response = await dispatch(
          actions.get({
            service: 'categories',
            options: {
              expand: ['custom.fields.rangeSupplierId'],
              perPage: input.perPage.value,
              page: input.page.value,
              sort: [
                {
                  by: input.sort.key,
                  direction: input.sort.order,
                },
              ],
              where,
            },
          }),
        )

        if (!cancelled.current) {
          setLoading(false)
          setData(response)
        }
      } catch (e) {
        console.log(e)
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, [
    input.page.value,
    input.perPage.value,
    input.sort,
    input.filters.name,
    input.filters.supplierIds,
  ])

  return {
    data,
    loading,
  }
}
