import { useIsAuthorized } from '@commercetools-frontend/permissions'
import LockedDiamondSVG from '@commercetools-frontend/assets/images/locked-diamond.svg'
import { MaintenancePageLayout } from '@commercetools-frontend/application-components'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { PERMISSIONS } from './constants'
import { ProductImageScenesContainer } from './components/product-image-scenes/product-image-scenes'
import { ProductListContainer } from './components/products'
import { ProductImageScenesDetailsContainer } from './components/product-image-scenes/product-image-scenes-details'
import { ProductRangesContainer } from './components/product-ranges/product-ranges'
import { ProductRangesDetailsContainer } from './components/product-ranges/product-ranges-details'

function PageUnauthorized() {
  return (
    <MaintenancePageLayout
      imageSrc={LockedDiamondSVG}
      title="Not enough permissions to access this resource"
      paragraph1="We recommend to contact your project administrators for further questions."
    />
  )
}

export const ApplicationRoutes = () => {
  const match = useRouteMatch()
  const canManageProducts = useIsAuthorized({
    demandedPermissions: [PERMISSIONS.Manage],
  })

  if (!canManageProducts) {
    return <PageUnauthorized />
  }

  return (
    <Switch>
      <Redirect
        exact
        from={`${match.path}/`}
        to={`${match.path}/product-mappings`}
      />

      <Route
        path={`${match.path}/product-mappings`}
        component={ProductListContainer}
      />

      <Route
        exact
        path={`${match.path}/product-image-scenes`}
        component={ProductImageScenesContainer}
      />

      <Route
        exact
        path={[
          `${match.path}/product-image-scenes/new`,
          `${match.path}/product-image-scenes/:id`,
        ]}
        component={ProductImageScenesDetailsContainer}
      />

      <Route path={`${match.path}/product-ranges`}>
        <ProductRangesContainer />

        <Route
          path={`${match.path}/product-ranges/:id`}
          component={ProductRangesDetailsContainer}
        />
      </Route>
    </Switch>
  )
}
