import React, { useEffect, useState } from 'react'
import LoadingSpinner from '@commercetools-uikit/loading-spinner'
import SelectInput, { TCustomEvent } from '@commercetools-uikit/select-input'
import { useDebouncedCallback } from 'use-debounce'
import { SearchApiResponseResult } from '../../../types'
import { useTemplateSearch } from './useTemplateSearch'
import style from './TemplateSearchField.mod.css'

type SearchOption = { value: string; label: string }

export function TemplateSearchField({
  onSelected,
  onCleared,
  value,
  label,
  id,
  placeholder,
  hasError,
}: {
  onSelected: (result: SearchApiResponseResult) => void
  onCleared: () => void
  value: string
  label: string
  id: string
  hasError: boolean
  placeholder: React.ReactNode
}) {
  const [results, setResults] = useState<SearchApiResponseResult[] | undefined>(
    undefined,
  )

  const [options, setOptions] = useState<SearchOption[]>([{ value, label }])
  const { search: searchFunc, searching } = useTemplateSearch({
    setResult: searchResults => {
      setResults(searchResults)
    },
    onError: () => {
      setResults([])
    },
  })

  const debouncedSearch = useDebouncedCallback(async (searchValue: string) => {
    await searchFunc(searchValue)
  }, 750)

  useEffect(() => {
    if (results && results.length > 0) {
      const newOptions = results.map(result => ({
        value: result.templateId,
        label: result.templateData.name,
      }))
      setOptions(newOptions)
    } else {
      setOptions([])
    }
  }, [results])

  return (
    <div className={style['std-c-template-search-field']}>
      {searching && (
        <div
          role="search"
          className={style['std-c-template-search-field__icon-container']}
        >
          <LoadingSpinner scale="s" maxDelayDuration={1} />
        </div>
      )}
      <SelectInput
        name={`search-input-${id}`}
        data-testid={`search-input-${id}`}
        placeholder={placeholder}
        value={value}
        hasError={hasError}
        isClearable
        isSearchable
        backspaceRemovesValue
        onInputChange={(newValue: string, actionMeta: { action: string }) => {
          if (actionMeta.action === 'input-change') {
            if (newValue?.length >= 3) {
              // Only search if there aren't already results that match the prefix term in the list
              if (
                !results?.find(t =>
                  t.templateData.name
                    .toLowerCase()
                    .startsWith(newValue.toLowerCase()),
                )
              ) {
                debouncedSearch(newValue)
              }
            } else {
              setResults([])
            }
          }
        }}
        onChange={(event: TCustomEvent) => {
          const selected =
            results && results.find(r => r.templateId === event.target.value)
          if (selected) {
            onSelected(selected as SearchApiResponseResult)
          } else {
            onCleared()
            setResults([])
          }
        }}
        options={!options?.length && value ? [{ value, label }] : options}
      />
    </div>
  )
}
