import { actions, useAsyncDispatch } from '@commercetools-frontend/sdk'
import { useEffect, useRef, useState } from 'react'
import { DEFAULT_LOCALE } from '../../constants'
import { ProductResults } from '../../types'

const defaultEmptyResult = {
  count: 0,
  offset: 0,
  limit: 0,
  total: 0,
  results: [],
  facets: [],
}

export const useProductSearch = (
  term: string,
  limit: number,
  offset: number,
  fuzzy: boolean,
  sort: string,
  ascending: boolean,
  rangeCategoryIds: string[],
  productTypeIds: string[],
) => {
  const cancelled = useRef(false)

  const [results, setResults] = useState<ProductResults>(defaultEmptyResult)
  const [hasError, setHasError] = useState<boolean>(false)
  const dispatch = useAsyncDispatch()

  useEffect(() => {
    const execute = async () => {
      cancelled.current = false
      setHasError(false)

      const filter = []
      if (rangeCategoryIds.length > 0) {
        filter.push(
          `variants.attributes.product-range.id:${rangeCategoryIds
            .map(id => `"${id}"`)
            .join(',')}`,
        )
      }

      if (productTypeIds.length > 0) {
        filter.push(
          `productType.id:${productTypeIds.map(id => `"${id}"`).join(',')}`,
        )
      }

      const direction = ascending ? 'asc' : 'desc'
      const page = offset ? offset / limit + 1 : 1
      const options = {
        staged: true,
        expand: ['productType'],
        perPage: limit,
        fuzzy: Boolean(fuzzy),
        sort: [{ by: sort, direction }],
        page,
        text: term
          ? {
              language: DEFAULT_LOCALE,
              value: term,
            }
          : undefined,
        filter,
      }

      const getRequest = actions.get({
        service: 'productProjectionsSearch',
        options,
      })

      const searchResults = (await dispatch(getRequest)) as ProductResults

      if (!cancelled.current) {
        setResults(searchResults)
      }
    }

    execute().catch(() => {
      if (!cancelled.current) {
        setHasError(true)
        setResults(defaultEmptyResult)
      }
    })

    return () => {
      cancelled.current = true
    }
  }, [
    term,
    limit,
    offset,
    fuzzy,
    sort,
    ascending,
    rangeCategoryIds,
    productTypeIds,
  ])

  return {
    results,
    hasError,
  }
}
