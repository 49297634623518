import { FC } from 'react'
import { ConfirmationDialog } from '@commercetools-frontend/application-components'
import Spacings from '@commercetools-uikit/spacings'
import Text from '@commercetools-uikit/text'

type ProductImageScenesConfirmationProps = {
  count: number
  onConfirm: () => void
  onClose: () => void
}

export const ProductImageScenesConfirmation: FC<
  ProductImageScenesConfirmationProps
> = props => {
  return (
    <ConfirmationDialog
      title="Update Product Image Scene Collection"
      isOpen={true}
      onClose={props.onClose}
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
    >
      <Spacings.Stack scale="m">
        <Text.Body>
          This will cause image updates to {props.count} products.
        </Text.Body>
        <Text.Body>Do you wish to continue?</Text.Body>
      </Spacings.Stack>
    </ConfirmationDialog>
  )
}
