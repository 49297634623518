import React from 'react'
import Text from '@commercetools-uikit/text'

export function NoResultsMessage() {
  return (
    <>
      <Text.Subheadline as="h4">
        Sorry, we didn&apos;t find any products this time.
      </Text.Subheadline>
      <Text.Body as="span">
        <Text.Body>Suggestions:</Text.Body>
        <ul>
          <li>Search by product name</li>
          <li>Use the range dropdown</li>
          <li>Sort the table by date</li>
        </ul>
      </Text.Body>
    </>
  )
}
