import {
  TSdkActionPost,
  useAsyncDispatch,
  actions,
  TSdkActionGet,
} from '@commercetools-frontend/sdk'
import { useEffect, useRef, useState } from 'react'
import {
  ProductImageScene,
  ProductImageSceneCollection,
} from '@moonpig/studio-product-personalisation-types'
import { MAPPING_API_HOST } from '../../constants'
import { getProjectKey } from '../../services'
import { HttpErrorType } from '../../types/dispatch'
import type { SceneFormValue } from '../../components/product-image-scenes/hooks/use-product-image-scene-form'

type ActionProductImageSceneCollectionSuccess = {
  type: 'success'
  imageSceneCollectionId: string
}

type ActionProductImageSceneCollectionValidation = {
  type: 'validation'
  messages: Array<{ field: string; reason: string }>
}

type ActionProductImageSceneCollectionError = {
  type: 'error'
}

export type CreateProductImageSceneCollectionInput = {
  name: string
  productType: string
  scenes: Omit<SceneFormValue, 'name' | 'preview' | 'zip'>[]
  actor: string
}

type CreateProductImageSceneCollectionResult =
  | ActionProductImageSceneCollectionSuccess
  | ActionProductImageSceneCollectionValidation
  | ActionProductImageSceneCollectionError

export const useCreateProductImageSceneCollection = () => {
  const dispatch = useAsyncDispatch<
    TSdkActionPost,
    CreateProductImageSceneCollectionResult
  >()

  const action = async (payload: CreateProductImageSceneCollectionInput) => {
    try {
      return await dispatch(
        actions.post({
          uri: '/proxy/forward-to',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-Forward-To': `${MAPPING_API_HOST}/product-image-scene-collection`,
            'X-Project-Key': getProjectKey(),
            'Accept-version': 'v2',
          },
          payload,
        }),
      )
    } catch (e: unknown) {
      if ((e as HttpErrorType).name === 'HttpError') {
        return (e as HttpErrorType).body as
          | ActionProductImageSceneCollectionValidation
          | ActionProductImageSceneCollectionError
      }

      throw e
    }
  }

  return { action }
}

export type UpdateProductImageSceneCollectionInput = {
  id: string
  name: string
  scenes: Omit<SceneFormValue, 'name' | 'preview' | 'zip'>[]
  actor: string
}

type UpdateProductImageSceneCollectionResult =
  | ActionProductImageSceneCollectionSuccess
  | ActionProductImageSceneCollectionValidation
  | ActionProductImageSceneCollectionError

export const useUpdateProductImageSceneCollection = () => {
  const dispatch = useAsyncDispatch<
    TSdkActionPost,
    UpdateProductImageSceneCollectionResult
  >()

  const action = async (payload: UpdateProductImageSceneCollectionInput) => {
    try {
      return await dispatch(
        actions.post({
          uri: '/proxy/forward-to',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-Forward-To': `${MAPPING_API_HOST}/product-image-scene-collection/${payload.id}`,
            'X-Project-Key': getProjectKey(),
            'Accept-version': 'v2',
          },
          payload,
        }),
      )
    } catch (e: unknown) {
      if ((e as HttpErrorType).name === 'HttpError') {
        return (e as HttpErrorType).body as
          | ActionProductImageSceneCollectionValidation
          | ActionProductImageSceneCollectionError
      }

      throw e
    }
  }

  return { action }
}

type ProductImageSceneCollectionsFetcherProps = {
  name?: string
  productType?: string
}

type ProductImageSceneCollectionsFetcherSuccess = {
  type: 'success'
  collections: ProductImageSceneCollection[]
}

type ProductImageSceneCollectionsFetcherError = {
  type: 'error'
  reason: string
}

type ProductImageSceneCollectionsFetcherResult =
  | ProductImageSceneCollectionsFetcherSuccess
  | ProductImageSceneCollectionsFetcherError

export const useProductImageSceneCollectionsFetcher = (
  props: ProductImageSceneCollectionsFetcherProps,
) => {
  const cancelled = useRef<boolean>(false)

  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<
    | {
        collections: ProductImageSceneCollection[]
      }
    | undefined
  >(undefined)

  const dispatch = useAsyncDispatch<
    TSdkActionGet,
    ProductImageSceneCollectionsFetcherResult
  >()

  useEffect(() => {
    cancelled.current = false

    const action = async () => {
      if (!cancelled.current) {
        setLoading(true)
      }

      const params: Record<string, string | undefined> = {
        name: props.name,
        productType: props.productType,
      }

      const searchParams = new URLSearchParams(
        Object.entries(params)
          .filter(([_, value]) => value !== undefined)
          .map(([key, value]) => [key, value as string]),
      )

      const uri = `${MAPPING_API_HOST}/product-image-scene-collection`
      const query = searchParams.toString()
      const url = [uri, query].filter(Boolean).join('?')

      const response = await dispatch(
        actions.get({
          uri: '/proxy/forward-to',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-Forward-To': url,
            'X-Project-Key': getProjectKey(),
            'Accept-version': 'v2',
          },
        }),
      )

      if (!cancelled.current) {
        setLoading(false)

        if (response.type === 'success') {
          setData(response)
          setError(undefined)
        }

        if (response.type === 'error') {
          setError(response.reason)
          setData(undefined)
        }
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, [dispatch, props.name, props.productType])

  return { data, error, loading }
}

type ProductImageSceneDetailsFetcherProps = {
  id: string
}

type ProductImageSceneCollectionDetailsSuccess = {
  type: 'success'
  productImageSceneCollection: ProductImageSceneCollection
  productImageScenes: ProductImageScene[]
  productsUsingProductImageSceneCollection: string[]
}

type ProductImageSceneCollectionDetailsError = {
  type: 'error'
  reason: string
}

type ProductImageSceneCollectionDetailsResult =
  | ProductImageSceneCollectionDetailsSuccess
  | ProductImageSceneCollectionDetailsError

export const useProductImageSceneCollectionDetailsFetcher = (
  props: ProductImageSceneDetailsFetcherProps,
) => {
  const cancelled = useRef<boolean>(false)

  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<
    | {
        productImageSceneCollection: ProductImageSceneCollection
        productImageScenes: ProductImageScene[]
        productsUsingProductImageSceneCollection: string[]
      }
    | undefined
  >(undefined)

  const dispatch = useAsyncDispatch<
    TSdkActionGet,
    ProductImageSceneCollectionDetailsResult
  >()

  useEffect(() => {
    const action = async () => {
      if (!cancelled.current) {
        setLoading(true)
      }

      const response = await dispatch(
        actions.get({
          uri: '/proxy/forward-to',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-Forward-To': `${MAPPING_API_HOST}/product-image-scene-collection/${props.id}`,
            'X-Project-Key': getProjectKey(),
            'Accept-version': 'v2',
          },
        }),
      )

      if (!cancelled.current) {
        setLoading(false)

        if (response.type === 'success') {
          setData({
            productImageSceneCollection: response.productImageSceneCollection,
            productImageScenes: response.productImageScenes,
            productsUsingProductImageSceneCollection:
              response.productsUsingProductImageSceneCollection,
          })
          setError(undefined)
        }

        if (response.type === 'error') {
          setError(response.reason)
          setData(undefined)
        }
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, [props.id])

  return { data, error, loading }
}

type ProductImageSceneSignedUrlResult =
  | { type: 'success'; uploadUrl: string }
  | { type: 'error' }

export const useProductImageSceneSignedUrlFetcher = () => {
  const dispatch = useAsyncDispatch<
    TSdkActionPost,
    ProductImageSceneSignedUrlResult
  >()

  const getSignedUrl = async (fileName: string) => {
    const action = actions.post({
      uri: '/proxy/forward-to',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-Forward-To': `${MAPPING_API_HOST}/product-image-scene/signed-url`,
        'X-Project-Key': getProjectKey(),
        'Accept-version': 'v2',
      },
      payload: {
        fileName,
      },
    })

    return dispatch(action)
  }

  return {
    getSignedUrl,
  }
}

export const useProductImageSceneUploader = () => {
  const upload = async (file: File, signedUrl: string) => {
    try {
      await fetch(signedUrl, {
        method: 'PUT',
        body: file,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return {
    upload,
  }
}
