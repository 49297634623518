import {
  Category,
  CategoryPagedQueryResponse,
} from '@commercetools/platform-sdk'
import { actions, useAsyncDispatch } from '@commercetools-frontend/sdk'
import { useEffect, useRef, useState } from 'react'
import { DEFAULT_LOCALE, RANGE_PARENT_CATEGORY_ID } from '../../../constants'
import { Range } from '../../../types/range'

export const useRanges = (): Range[] => {
  const cancelled = useRef(false)

  const [ranges, setResults] = useState<Range[]>([])
  const dispatch = useAsyncDispatch()

  useEffect(() => {
    cancelled.current = false

    const execute = async (
      page = 1,
      rangesToInclude: Range[] = [],
    ): Promise<Range[]> => {
      const getRequest = actions.get({
        service: 'categories',
        options: {
          where: [`parent(id="${RANGE_PARENT_CATEGORY_ID}")`],
          perPage: 500,
          page,
          sort: [{ by: `name.${DEFAULT_LOCALE}`, direction: 'asc' }],
        },
      })

      const response = (await dispatch(
        getRequest,
      )) as CategoryPagedQueryResponse
      const { results, offset, total } = response
      const mapped = results.map((r: Category) => ({
        id: r.id,
        name: r.name[DEFAULT_LOCALE],
        supplierId: r.custom?.fields?.rangeSupplierId?.id ?? '',
      }))
      const updatedRanges = [...rangesToInclude, ...mapped]

      if (offset + results.length >= (total ?? 0)) {
        if (!cancelled.current) {
          setResults(updatedRanges)
        }

        return updatedRanges
      }

      return execute(page + 1, updatedRanges)
    }

    execute().catch()

    return () => {
      cancelled.current = true
    }
  }, [dispatch])

  return ranges
}
