import React from 'react'
import { TCustomEvent } from '@commercetools-uikit/select-input'
import SelectField from '@commercetools-uikit/select-field'
import { MappingApiResponse } from '../../../types'
import { useProductImageSceneCollections } from './useProductImageSceneCollections'

type ProductImageSceneCollectionSelectorProps = {
  productTypeKey: string
  productMapping: MappingApiResponse
  onSceneCollectionSelected: (sceneId: string | undefined) => void
}

export function ProductImageSceneCollectionSelector({
  productTypeKey,
  productMapping,
  onSceneCollectionSelected,
}: ProductImageSceneCollectionSelectorProps) {
  const { productImageSceneCollections, loading } =
    useProductImageSceneCollections(productTypeKey)

  const sceneCollectionOptions = React.useMemo(() => {
    return productImageSceneCollections.map(pisc => ({
      value: pisc.id,
      label: pisc.name,
    }))
  }, [productImageSceneCollections])

  const sceneCollectionChanged = (event: TCustomEvent) => {
    if (typeof event?.target?.value === 'string') {
      onSceneCollectionSelected(event?.target?.value)
    } else if (!event?.target?.value) {
      onSceneCollectionSelected(undefined)
    }
  }

  return (
    <div>
      {!loading &&
        sceneCollectionOptions &&
        sceneCollectionOptions.length > 0 && (
          <SelectField
            title="Product Images"
            aria-label="Product Image Scene Collection ID"
            name="product-image-scene-collection-id"
            placeholder="Select a product image scene collection"
            isSearchable={true}
            options={sceneCollectionOptions}
            backspaceRemovesValue={true}
            horizontalConstraint={6}
            data-testid="product-image-scene-collection-dropdown"
            value={productMapping.productImageSceneCollectionId}
            isClearable={true}
            onChange={sceneCollectionChanged}
          />
        )}
    </div>
  )
}
