import SelectField from "@commercetools-uikit/select-field";
import Spacings from "@commercetools-uikit/spacings";
import { ProductRangeDetailForm as DetailsForm } from "../hooks/use-product-range-detail-form";
import { useStickerSets } from "../../../hooks/useStickerSets";

type ProductRangeDetailFormProps = {
  formik: DetailsForm;
};

export const ProductRangeDetailForm: React.FC<ProductRangeDetailFormProps> = (
  props
) => {
  const { formik } = props;
  const { stickerSets: availableStickerSets } = useStickerSets();

  const stickerSetOptions = availableStickerSets.map((stickerSet) => ({
    value: stickerSet.key,
    label: stickerSet.name,
  }));

  return (
    <Spacings.Stack scale="l">
      <Spacings.Inline>
        <SelectField
          name="stickerSets"
          title={"Sticker Sets"}
          horizontalConstraint={13}
          isSearchable={true}
          isMulti={true}
          isClearable={true}
          value={formik.values.stickerSets}
          touched={formik.touched.stickerSets}
          placeholder="Search by Sticker Set"
          errors={
            SelectField.toFieldErrors<DetailsForm["values"]>(formik.errors)
              .stickerSets
          }
          options={stickerSetOptions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <SelectField
          name="profanityFilter"
          title={"Profanity Filter"}
          horizontalConstraint={13}
          isSearchable={false}
          isMulti={false}
          value={formik.values.profanityFilter}
          touched={formik.touched.profanityFilter}
          errors={
            SelectField.toFieldErrors<DetailsForm["values"]>(formik.errors)
              .profanityFilter
          }
          options={[
            { value: "enabled", label: "Enabled" },
            { value: "disabled", label: "Disabled" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isRequired
        />
        <SelectField
          name="aiStickers"
          title={"AI Stickers"}
          horizontalConstraint={13}
          isSearchable={false}
          isMulti={false}
          value={formik.values.aiStickers}
          touched={formik.touched.aiStickers}
          errors={
            SelectField.toFieldErrors<DetailsForm["values"]>(formik.errors)
              .aiStickers
          }
          options={[
            { value: "enabled", label: "Enabled" },
            { value: "disabled", label: "Disabled" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isRequired
        />
      </Spacings.Inline>
    </Spacings.Stack>
  );
};
