import { useEffect, useRef, useState } from 'react'
import {
  useAsyncDispatch,
  actions,
  TSdkActionGet,
} from '@commercetools-frontend/sdk'
import { Product } from '@commercetools/platform-sdk'
import { ProductNotFoundError } from '../../components/duplicate-product/ProductNotFoundError'

type ProductInput = {
  productKey?: string
}

export const useProduct = (input: ProductInput) => {
  const cancelled = useRef<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Product>()
  const [error, setError] = useState<ProductNotFoundError>()

  const dispatch = useAsyncDispatch<TSdkActionGet, Product>()

  useEffect(() => {
    cancelled.current = false

    if (input.productKey === undefined) {
      setLoading(false)

      return () => {
        cancelled.current = true
      }
    }

    const action = async () => {
      if (!cancelled.current) {
        setLoading(true)
      }

      try {
        const response = await dispatch(
          actions.get({
            service: 'products',
            options: {
              expand: ['productType'],
              key: input.productKey,
            },
          }),
        )

        if (!cancelled.current) {
          setLoading(false)
          setData(response)
        }
      } catch (caught: unknown) {
        if ((caught as Error).name === 'NotFound') {
          if (!cancelled.current) {
            setError(new ProductNotFoundError(input.productKey as string))
            setLoading(false)
          }
        } else {
          throw caught
        }
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, [input.productKey])

  return {
    data,
    loading,
    error,
  }
}
