import React, { FC } from 'react'
import TextField from '@commercetools-uikit/text-field'
import NumberField from '@commercetools-uikit/number-field'
import DateField from '@commercetools-uikit/date-field'
import { FormDialog } from '@commercetools-frontend/application-components'
import { generatePath, useHistory } from 'react-router-dom'
import { useApplicationContext } from '@commercetools-frontend/application-shell-connectors'
import SelectField from '@commercetools-uikit/select-field'
import Spacings from '@commercetools-uikit/spacings'
import Label from '@commercetools-uikit/label'
import { useShowNotification } from '@commercetools-frontend/actions-global'
import { DOMAINS } from '@commercetools-frontend/constants'
import {
  ProductPrintForm,
  factoryOptions,
  officeOptions,
  useProductPrintPdfForm,
} from './hooks/use-product-print-pdf-form'
import style from './ProductPrintModal.mod.css'

type ProductPrintModalProps = {
  productKey: string
  productType: string
  productVariants: Array<{ sku: string; size?: string; shape?: string }>
}

export const ProductPrintModal: FC<ProductPrintModalProps> = props => {
  const history = useHistory()
  const showNotification = useShowNotification()

  const { projectKey, entryPointUriPath } = useApplicationContext(context => ({
    projectKey: context.project?.key,
    entryPointUriPath: context.environment.entryPointUriPath,
  }))

  if (!projectKey) {
    return null
  }

  const handleSubmitted = () => {
    showNotification({
      kind: 'success',
      domain: DOMAINS.SIDE,
      text: 'Product Print has been requested successfully!',
    })

    history.push(
      generatePath('/:projectKey/:entryPointUriPath/product-mappings', {
        projectKey,
        entryPointUriPath,
      }),
    )
  }

  const handleFailed = () => {
    showNotification({
      kind: 'error',
      domain: DOMAINS.SIDE,
      text: 'Product Print failed to be requested.',
    })
  }

  const handleClose = () => {
    history.push(
      generatePath('/:projectKey/:entryPointUriPath/product-mappings', {
        projectKey,
        entryPointUriPath,
      }),
    )
  }

  const productPrintForm = useProductPrintPdfForm({
    productId: props.productKey,
    productType: props.productType,
    productVariants: props.productVariants,
    onSubmitted: () => handleSubmitted(),
    onFailed: () => handleFailed(),
  })

  const officeSelectableOptions = React.useMemo(() => {
    const options = officeOptions(projectKey).map(office => ({
      label: office.label,
      value: office.key,
    }))

    options.push({ label: 'Custom', value: 'custom' })

    return options
  }, [officeOptions, projectKey])

  const factorySelectableOptions = React.useMemo(() => {
    return factoryOptions(projectKey).map(factory => ({
      label: `${factory.factory} (${factory.location})`,
      value: factory.key,
    }))
  }, [factoryOptions, projectKey])

  const skusSelectableOptions = React.useMemo(() => {
    return props.productVariants.map(variant => ({
      label: variant.sku,
      value: variant.sku,
    }))
  }, [props.productVariants])

  const minDespatchOnDate = React.useMemo(() => {
    const minDate = new Date()
    minDate.setDate(minDate.getDate() - 1)
    return minDate.toISOString()
  }, [])

  return (
    <FormDialog
      title="Create Product Test Print"
      isOpen={true}
      onClose={handleClose}
      isPrimaryButtonDisabled={productPrintForm.formik.isSubmitting}
      onSecondaryButtonClick={e => {
        productPrintForm.formik.handleReset(e)
        handleClose()
      }}
      onPrimaryButtonClick={() => productPrintForm.formik.handleSubmit()}
      labelPrimary={
        productPrintForm.formik.isSubmitting
          ? 'Sending ...'
          : 'Create Product Test Print'
      }
    >
      <Spacings.Stack>
        <Spacings.Inline>
          <SelectField
            horizontalConstraint={8}
            menuPortalTarget={document.body}
            menuPortalZIndex={99999}
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            id="productSku"
            name="productSku"
            title="Product Variant"
            placeholder="Select Product Variant"
            isRequired={true}
            value={productPrintForm.formik.values.productSku}
            errors={
              SelectField.toFieldErrors<ProductPrintForm['values']>(
                productPrintForm.formik.errors,
              )?.productSku
            }
            options={skusSelectableOptions}
            touched={productPrintForm.formik.touched.productSku}
            onChange={productPrintForm.formik.handleChange}
            onBlur={productPrintForm.formik.handleBlur}
          />

          <NumberField
            horizontalConstraint={4}
            id="quantity"
            name="quantity"
            title="Quantity"
            isRequired={true}
            value={productPrintForm.formik.values.quantity}
            min={1}
            max={10}
            step={1}
            errors={
              NumberField.toFieldErrors<ProductPrintForm['values']>(
                productPrintForm.formik.errors,
              )?.quantity
            }
            renderError={errorKey => {
              switch (errorKey) {
                case 'out_of_range':
                  return 'The quantity is outside the allowed range.'
                default:
                  return null
              }
            }}
            touched={productPrintForm.formik.touched.quantity}
            onChange={productPrintForm.formik.handleChange}
            onBlur={productPrintForm.formik.handleBlur}
          />
        </Spacings.Inline>

        <Spacings.Inline>
          <SelectField
            horizontalConstraint={6}
            menuPortalTarget={document.body}
            menuPortalZIndex={99999}
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            id="factory"
            name="factory"
            title="Factory"
            placeholder="Select Factory"
            isRequired={true}
            value={productPrintForm.formik.values.factory}
            errors={
              SelectField.toFieldErrors<ProductPrintForm['values']>(
                productPrintForm.formik.errors,
              )?.factory
            }
            options={factorySelectableOptions}
            touched={productPrintForm.formik.touched.productSku}
            onChange={productPrintForm.formik.handleChange}
            onBlur={productPrintForm.formik.handleBlur}
          />

          <div className={style['custom-date-field-input']}>
            <DateField
              horizontalConstraint={6}
              id="despatchOn"
              name="despatchOn"
              title="Despatch On"
              placeholder="Select Despatch Date"
              isRequired={true}
              value={productPrintForm.formik.values.despatchOn}
              minValue={minDespatchOnDate}
              errors={
                DateField.toFieldErrors<ProductPrintForm['values']>(
                  productPrintForm.formik.errors,
                )?.despatchOn
              }
              touched={productPrintForm.formik.touched.productSku}
              onChange={productPrintForm.formik.handleChange}
              onBlur={productPrintForm.formik.handleBlur}
            />
          </div>
        </Spacings.Inline>

        <Label>Delivery Address</Label>
        <Spacings.Stack>
          <Spacings.Inline>
            <TextField
              horizontalConstraint={6}
              id="addressFirstName"
              name="addressFirstName"
              title="First Name"
              isRequired={true}
              value={productPrintForm.formik.values.addressFirstName}
              errors={
                TextField.toFieldErrors<ProductPrintForm['values']>(
                  productPrintForm.formik.errors,
                )?.addressFirstName
              }
              touched={productPrintForm.formik.touched.addressFirstName}
              onChange={productPrintForm.formik.handleChange}
              onBlur={productPrintForm.formik.handleBlur}
            />
            <TextField
              horizontalConstraint={6}
              id="addressLastName"
              name="addressLastName"
              title="Last Name"
              isRequired={true}
              value={productPrintForm.formik.values.addressLastName}
              errors={
                TextField.toFieldErrors<ProductPrintForm['values']>(
                  productPrintForm.formik.errors,
                )?.addressLastName
              }
              touched={productPrintForm.formik.touched.addressLastName}
              onChange={productPrintForm.formik.handleChange}
              onBlur={productPrintForm.formik.handleBlur}
            />
          </Spacings.Inline>
        </Spacings.Stack>

        <Spacings.Stack>
          <Spacings.Inline>
            <SelectField
              horizontalConstraint={6}
              menuPortalTarget={document.body}
              menuPortalZIndex={99999}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              id="address_type"
              name="address_type"
              title="Address"
              placeholder="Select Address"
              isRequired={true}
              value={productPrintForm.formik.values.address_type}
              errors={
                SelectField.toFieldErrors<ProductPrintForm['values']>(
                  productPrintForm.formik.errors,
                )?.address_type
              }
              options={officeSelectableOptions}
              touched={productPrintForm.formik.touched.address_type}
              onChange={productPrintForm.formik.handleChange}
              onBlur={productPrintForm.formik.handleBlur}
            />
            <SelectField
              horizontalConstraint={6}
              menuPortalTarget={document.body}
              menuPortalZIndex={99999}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              id="method"
              name="method"
              title="Method"
              placeholder="Select Method"
              isRequired={true}
              value={productPrintForm.formik.values.method}
              errors={
                SelectField.toFieldErrors<ProductPrintForm['values']>(
                  productPrintForm.formik.errors,
                )?.address_type
              }
              options={[
                { value: 'send_to_them', label: 'Send To Them' },
                { value: 'send_to_me', label: 'Send To Me' },
              ]}
              touched={productPrintForm.formik.touched.address_type}
              onChange={productPrintForm.formik.handleChange}
              onBlur={productPrintForm.formik.handleBlur}
            />
          </Spacings.Inline>
        </Spacings.Stack>

        {productPrintForm.formik.values.address_type === 'custom' && (
          <>
            <Spacings.Stack>
              <Spacings.Inline>
                <TextField
                  horizontalConstraint={4}
                  id="addressHouseNumber"
                  name="addressHouseNumber"
                  title="House Number"
                  isRequired={true}
                  value={productPrintForm.formik.values.addressHouseNumber}
                  errors={
                    TextField.toFieldErrors<ProductPrintForm['values']>(
                      productPrintForm.formik.errors,
                    ).addressHouseNumber
                  }
                  touched={productPrintForm.formik.touched.addressHouseNumber}
                  onChange={productPrintForm.formik.handleChange}
                  onBlur={productPrintForm.formik.handleBlur}
                />

                <TextField
                  horizontalConstraint={8}
                  id="addressStreetName"
                  name="addressStreetName"
                  title="Street Name"
                  isRequired={true}
                  value={productPrintForm.formik.values.addressStreetName}
                  errors={
                    TextField.toFieldErrors<ProductPrintForm['values']>(
                      productPrintForm.formik.errors,
                    ).addressStreetName
                  }
                  touched={productPrintForm.formik.touched.addressStreetName}
                  onChange={productPrintForm.formik.handleChange}
                  onBlur={productPrintForm.formik.handleBlur}
                />
              </Spacings.Inline>

              <Spacings.Inline>
                <TextField
                  horizontalConstraint={6}
                  id="addressCityTown"
                  name="addressCityTown"
                  title="City / Town"
                  isRequired={true}
                  value={productPrintForm.formik.values.addressCityTown}
                  errors={
                    TextField.toFieldErrors<ProductPrintForm['values']>(
                      productPrintForm.formik.errors,
                    ).addressCityTown
                  }
                  touched={productPrintForm.formik.touched.addressCityTown}
                  onChange={productPrintForm.formik.handleChange}
                  onBlur={productPrintForm.formik.handleBlur}
                />

                <TextField
                  horizontalConstraint={6}
                  id="addressPostcode"
                  name="addressPostcode"
                  title="Postcode"
                  isRequired={true}
                  value={productPrintForm.formik.values.addressPostcode}
                  errors={
                    TextField.toFieldErrors<ProductPrintForm['values']>(
                      productPrintForm.formik.errors,
                    ).addressPostcode
                  }
                  touched={productPrintForm.formik.touched.addressPostcode}
                  onChange={productPrintForm.formik.handleChange}
                  onBlur={productPrintForm.formik.handleBlur}
                />
              </Spacings.Inline>

              <Spacings.Inline>
                <TextField
                  horizontalConstraint={6}
                  id="addressCounty"
                  name="addressCounty"
                  title="County"
                  isRequired={true}
                  value={productPrintForm.formik.values.addressCounty}
                  errors={
                    TextField.toFieldErrors<ProductPrintForm['values']>(
                      productPrintForm.formik.errors,
                    ).addressCounty
                  }
                  touched={productPrintForm.formik.touched.addressCounty}
                  onChange={productPrintForm.formik.handleChange}
                  onBlur={productPrintForm.formik.handleBlur}
                />

                <SelectField
                  horizontalConstraint={6}
                  menuPortalTarget={document.body}
                  menuPortalZIndex={99999}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  id="addressCountry"
                  name="addressCountry"
                  title="Country"
                  isRequired={true}
                  value={productPrintForm.formik.values.addressCountry}
                  errors={
                    SelectField.toFieldErrors<ProductPrintForm['values']>(
                      productPrintForm.formik.errors,
                    )?.addressCountry
                  }
                  options={[
                    { value: 'GB', label: 'United Kingdom' },
                    { value: 'NL', label: 'Netherlands' },
                  ]}
                  touched={productPrintForm.formik.touched.addressCountry}
                  onChange={productPrintForm.formik.handleChange}
                  onBlur={productPrintForm.formik.handleBlur}
                />
              </Spacings.Inline>
            </Spacings.Stack>
          </>
        )}
      </Spacings.Stack>
    </FormDialog>
  )
}
