import React, { useState } from 'react'
import Label from '@commercetools-uikit/label'
import SelectInput, {
  TCustomEvent,
  TSelectInputProps,
} from '@commercetools-uikit/select-input'
import { useProductTypes } from '../../../hooks/useProductServices'

type ProductTypeDropdownProps = {
  onProductTypesSelected: (productTypeIds: string[]) => void
  isMulti: boolean
  horizontalConstraint: TSelectInputProps['horizontalConstraint']
}

export function ProductTypeDropdown({
  onProductTypesSelected,
  isMulti,
  horizontalConstraint,
}: ProductTypeDropdownProps) {
  const [selectedProductTypeIds, setSelectedProductTypeIds] = useState<
    string[]
  >([])
  const { data: productTypes } = useProductTypes()
  const handleProductTypeChange = (event: TCustomEvent) => {
    let value: string[] = []
    if (event?.target?.value) {
      value = Array.isArray(event.target.value)
        ? event.target.value
        : [event.target.value]
    }
    onProductTypesSelected(value)
    setSelectedProductTypeIds(value)
  }
  return (
    <>
      <Label>{isMulti ? 'Product Types' : 'Product Type'}</Label>
      <SelectInput
        name="Select product type"
        placeholder={isMulti ? 'Select product types' : 'Select a product type'}
        isSearchable={true}
        isMulti={isMulti}
        options={productTypes?.map(pt => ({
          label: pt.name,
          value: pt.id,
        }))}
        onChange={handleProductTypeChange}
        backspaceRemovesValue={true}
        horizontalConstraint={horizontalConstraint}
        data-testid="product-type-dropdown"
        value={isMulti ? selectedProductTypeIds : selectedProductTypeIds[0]}
        isClearable={true}
        closeMenuOnSelect={true}
        aria-label="Select product types"
      />
    </>
  )
}
