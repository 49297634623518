import {
  useAsyncDispatch,
  actions,
  TSdkActionGet,
} from '@commercetools-frontend/sdk'
import { useRef, useState, useEffect } from 'react'
import { MAPPING_API_HOST } from '../../constants'
import { getProjectKey } from '../../services'
import { ProductTypeSettings } from '../../types'

export const useProductTypeSettingsFetcher = ({
  productTypeKey,
}: {
  productTypeKey: string
}) => {
  const cancelled = useRef<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<ProductTypeSettings>()

  const dispatch = useAsyncDispatch<TSdkActionGet, ProductTypeSettings>()

  useEffect(() => {
    cancelled.current = false

    const action = async () => {
      if (!cancelled.current) {
        setLoading(true)
      }

      const response = await dispatch(
        actions.get({
          uri: '/proxy/forward-to',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-Forward-To': `${MAPPING_API_HOST}/product-type-settings/${productTypeKey}`,
            'X-Project-Key': getProjectKey(),
            'Accept-version': 'v2',
          },
        }),
      )

      if (!cancelled.current) {
        setLoading(false)
        setData(response)
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, [dispatch, productTypeKey])

  return { data, loading }
}
