import { actions, useAsyncDispatch } from '@commercetools-frontend/sdk'

const useInventoryServices = () => {
  const dispatch = useAsyncDispatch()

  const updateVariantInventoryBySku = async (sku: string, quantity: number) => {
    async function execute() {
      return dispatch(
        actions.post({
          service: 'inventory',
          options: {},
          payload: {
            sku,
            quantityOnStock: quantity,
            availableQuantity: quantity,
          },
        }),
      )
    }

    return execute()
  }

  return {
    updateVariantInventoryBySku,
  }
}

export { useInventoryServices }
