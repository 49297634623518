import React, { useMemo, useState } from 'react'
import { Product } from '@commercetools/platform-sdk'
import Text from '@commercetools-uikit/text'
import LoadingSpinner from '@commercetools-uikit/loading-spinner'
import TextField from '@commercetools-uikit/text-field'
import Spacings from '@commercetools-uikit/spacings'
import { FormDialog } from '@commercetools-frontend/application-components'
import { ContentNotification } from '@commercetools-uikit/notifications'
import { useHistory } from 'react-router-dom'
import { useShowNotification } from '@commercetools-frontend/actions-global'
import { DOMAINS } from '@commercetools-frontend/constants'
import { DEFAULT_LOCALE } from '../../constants'
import { getAttributeFromProduct } from '../../utils/attributes'
import { useProduct } from '../../hooks/connectors/use-product-connector'
import { useApplicationUrl } from '../../hooks/useApplicationUrl'
import {
  ProductDuplicateForm,
  useProductDuplicateForm,
} from './hooks/use-duplicate-product-form'

const determineProductName = (product: Product | null): string => {
  return product?.masterData.staged.name?.[DEFAULT_LOCALE] || ''
}

export function DuplicateProductModal({
  productDuplicating,
}: {
  productDuplicating: Product
}) {
  const history = useHistory()
  const { url } = useApplicationUrl()
  const showNotification = useShowNotification()

  const [productName] = useState<string>(
    determineProductName(productDuplicating),
  )

  const missingAttributes = useMemo(() => {
    const missingAttributeLabels: string[] = []

    if (
      !productDuplicating.productType ||
      !productDuplicating.productType.obj?.attributes
    )
      return missingAttributeLabels

    const requiredProductAttributes =
      productDuplicating.productType.obj?.attributes.filter(a => a.isRequired)

    requiredProductAttributes.forEach(attribute => {
      const productAttribute = getAttributeFromProduct(
        attribute.name,
        productDuplicating,
      )
      if (!productAttribute || !productAttribute.value) {
        const attributeLabel = attribute.label[DEFAULT_LOCALE] ?? attribute.name
        missingAttributeLabels.push(attributeLabel)
      }
    })

    return missingAttributeLabels
  }, [productDuplicating])

  const handleSubmitted = (
    productKey: string,
    duplicatedProductKey: string,
  ) => {
    showNotification({
      kind: 'success',
      domain: DOMAINS.SIDE,
      text: `Product duplicated successfully!`,
    })

    history.push(
      url(
        `product-mappings/${productKey}?duplicatedFrom=${duplicatedProductKey}`,
      ),
    )
  }

  const handleFailed = () => {
    showNotification({
      kind: 'error',
      domain: DOMAINS.SIDE,
      text: `Product duplicate failed.`,
    })
  }

  const form = useProductDuplicateForm({
    productDuplicating,
    values: {
      productKey: '',
      name: productName,
    },
    onSubmitted: (productKey: string) =>
      handleSubmitted(productKey, productDuplicating.key as string),
    onFailed: () => handleFailed(),
  })

  const onClose = () => {
    history.push(url('product-mappings'))
  }

  const canSubmitForm =
    !form.formik.isSubmitting && missingAttributes.length === 0

  return (
    <FormDialog
      title={`Duplicating product: ${productName}`}
      isOpen
      onClose={onClose}
      isPrimaryButtonDisabled={!canSubmitForm || form.formik.isSubmitting}
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={() => form.formik.handleSubmit()}
      labelPrimary={'Duplicate Product'}
      labelSecondary={'Cancel'}
    >
      {missingAttributes.length > 0 && (
        <ContentNotification type="error">
          <Spacings.Stack>
            <Text.Body>
              The product being duplicated does not have a value for the
              following attribute(s):
            </Text.Body>
            <ul aria-label="missing-attributes">
              {missingAttributes?.map(attribute => (
                <li key={attribute}>{attribute}</li>
              ))}
            </ul>
            <Text.Body>
              Please add a value for the missing attribute(s) before
              duplicating.
            </Text.Body>
          </Spacings.Stack>
        </ContentNotification>
      )}

      <Spacings.Stack scale="s" alignItems="stretch">
        <Text.Body>
          Enter details for the new Product to confirm you want to proceed. The
          Product key must be unique to identify the new product.
        </Text.Body>

        <TextField
          title="Product Key"
          id="productKey"
          name="productKey"
          isRequired
          value={form.formik.values.productKey}
          isAutofocussed
          horizontalConstraint={9}
          placeholder="New unique key for the product"
          onChange={form.formik.handleChange}
          onBlur={form.formik.handleBlur}
          touched={form.formik.touched.productKey}
          isDisabled={!canSubmitForm}
          errors={
            TextField.toFieldErrors<ProductDuplicateForm['values']>(
              form.formik.errors,
            ).productKey
          }
          renderError={errorKey => {
            if (errorKey === 'duplicate') {
              return 'A product with this key already exists.'
            }

            if (errorKey === 'invalid') {
              return 'Product key is invalid.'
            }

            return null
          }}
        />

        <TextField
          title="Product Name"
          id="name"
          name="name"
          isRequired
          value={form.formik.values?.name || ''}
          horizontalConstraint={9}
          placeholder="New unique name for the product"
          onChange={form.formik.handleChange}
          onBlur={form.formik.handleBlur}
          touched={form.formik.touched.name}
          isDisabled={!canSubmitForm}
          errors={
            TextField.toFieldErrors<ProductDuplicateForm['values']>(
              form.formik.errors,
            ).name
          }
        />
      </Spacings.Stack>
    </FormDialog>
  )
}

type DuplicateProductModalContainerProps = {
  duplicateProductKey: string
}

export const DuplicateProductModalContainer: React.FC<
  DuplicateProductModalContainerProps
> = props => {
  const { data: productDuplicating, loading: productDuplicatingLoading } =
    useProduct({
      productKey: props.duplicateProductKey,
    })

  if (productDuplicatingLoading) {
    return <LoadingSpinner />
  }

  if (productDuplicating === undefined) {
    return <>Unable to load product to duplicate.</>
  }

  return <DuplicateProductModal productDuplicating={productDuplicating} />
}
