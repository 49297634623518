import { Attribute, Product } from '@commercetools/platform-sdk'
import { KeyLabel } from '../types'

export const isKeyLabel = (value: unknown): value is KeyLabel => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'key' in value &&
    'label' in value
  )
}

export const getShapeAttribute = (product: Product): Attribute => {
  return product?.masterData?.current?.masterVariant?.attributes?.find(
    a => a.name === 'shape',
  )?.value
}

export const getShape = (shapeAttribute: Attribute) =>
  shapeAttribute && isKeyLabel(shapeAttribute) ? shapeAttribute.key : 'unknown'
